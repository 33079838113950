import { Button, Form, Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteOrderState } from "../../../api/orders";

const DeleteState = ({ isVisible, setVisible, order_id, state }: any) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate: deleteState, isLoading } = useMutation({
    mutationFn: deleteOrderState,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["orders", order_id] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    deleteState({ state_id: state?.id });
  };

  return (
    <>
      <Modal
        title="Delete State"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="deleteState"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Delete
          </Button>,
        ]}
      >
        <Form
          id="deleteState"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <div className="flex flex-col justify-center items-center">
            <div className="">
              Are you sure you want to
              <span className="text-red-600 px-1 font-bold">Delete</span>State
            </div>
            <div className="font-bold">{state?.title} ?</div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteState;
