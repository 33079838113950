import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import formatMoneyLong from "../../../../utils/formatMoneyLong";
import UpdatePrice from "./UpdatePrice";

export default function PriceWidget({ vehicle, vehicleId }: any) {
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  return (
    <div className="">
      <div className="flex justify-between items-center border-b border-t bg-gray-100 px-2 py-3">
        <div className="">Product Price</div>
        <button onClick={() => setUpdateModal(true)} className="">
          <FiEdit />
        </button>
      </div>

      <div className="">
        <div className="flex justify-between p-2 text-xs">
          <div className="">VAT Exclusive</div>
          <div className="w-32 text-right ">
            {formatMoneyLong(vehicle?.price || 0)} /=
          </div>
        </div>
        <div className="flex justify-between p-2 text-xs">
          <div className="">VAT Inclusive</div>
          <div className="w-32 text-right ">
            {formatMoneyLong(vehicle?.price * 1.18 || 0)} /=
          </div>
        </div>
      </div>
      <UpdatePrice
        isVisible={updateModal}
        setVisible={setUpdateModal}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
      />
    </div>
  );
}
