import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createPost = async ({ data }: any) => {
  const response = await postData({ data, uri: "posts" });
  return response;
};

const fetchPosts = async ({ cursor }: any) => {
  const response = await fetchData({
    uri: `posts/feed?cursor=${cursor}`,
  });
  return response;
};

const fetchPost = async ({ id }: any) => {
  const response = await fetchData({ uri: `posts/${id}` });
  return response;
};

const updatePost = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `posts/${id}` });
  return response;
};

const uploadImage = async ({ id, file, position, is_main }: any) => {
  const response = await uploadFile({
    file,
    uri: `posts/${id}/update/images?position=${position}&is_main=${is_main}`,
  });
  return response;
};

const deletePost = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `posts/${id}` });
  return response;
};

export {
  createPost,
  updatePost,
  fetchPosts,
  fetchPost,
  deletePost,
  uploadImage,
};
