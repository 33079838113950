import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createInventory = async ({ data }: any) => {
  const response = await postData({ data, uri: "inventories" });
  return response;
};

const fetchInventories = async () => {
  const response = await fetchData({ uri: "inventories" });
  return response;
};

const fetchInventory = async ({ id }: any) => {
  const response = await fetchData({ uri: `inventories/${id}` });
  return response;
};

const updateInventory = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `inventories/${id}` });
  return response;
};

const deleteInventory = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `inventories/${id}` });
  return response;
};

export {
  createInventory,
  updateInventory,
  fetchInventories,
  fetchInventory,
  deleteInventory,
};
