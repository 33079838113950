import { useState } from "react";
import placeholder from "../../../../assets/images/placeholder.png";
import UploadImage from "./UploadImage";
import vimagePositions from "../../../../utils/vimagePositions";
import VimagesPanel from "./VimagesPanel";
export default function VehicleImages({ vehicle, editable, vehicleId }: any) {
  const [vimagePanel, setVimagePanel] = useState<boolean>(false);
  const { e1, e2, e3, e4, i1, i2, i3, i4, m1, m2, m3, m4, o1, o2 } =
    vimagePositions({
      vehicle,
      placeholder,
    });

  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  return (
    <div className="grid grid-cols-12 gap-2  p-2">
      <div className="col-span-12 sm:col-span-6 bg-gray-100">
        <button
          onClick={() => {
            if (editable) {
              setSelected({ position: "e1", category: "exterior" });
              setVisible(true);
            }
          }}
          className=""
        >
          <img src={e1} />
        </button>
      </div>
      <div className="col-span-12 sm:col-span-6">
        <div className="flex justify-between">
          {/* INTERIOR */}
          <div className="">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300">
              EXTERIOR
            </div>
            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "e1", category: "exterior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={e1} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "e2", category: "exterior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={e2} width={150} />
                </button>
              </div>
            </div>
            <div className="flex mt-1">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "e3", category: "exterior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={e3} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "e4", category: "exterior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={e4} width={150} />
                </button>
              </div>
            </div>
          </div>

          {/* EXTERIOR */}
          <div className="ml-1">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300">
              INTERIOR
            </div>
            <div className="flex mb-1">
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i1", category: "interior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i1} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i2", category: "interior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i2} width={150} />
                </button>
              </div>
            </div>
            <div className="flex">
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i3", category: "interior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i3} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i4", category: "interior" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i4} width={150} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-2">
          {/* MECHANICAL */}
          <div className="">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300">
              MECHANICAL
            </div>
            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "m1", category: "mechanical" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={m1} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "m2", category: "mechanical" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={m2} width={150} />
                </button>
              </div>
            </div>
            <div className="flex mt-1">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "m3", category: "mechanical" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={m3} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "m4", category: "mechanical" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={m4} width={150} />
                </button>
              </div>
            </div>
          </div>

          {/* OTHERS */}
          <div className="ml-1">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300">
              OTHERS
            </div>
            <div className="flex mb-1">
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "o1", category: "others" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={o1} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "o2", category: "others" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={o2} width={150} />
                </button>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={() => setVimagePanel(true)}
                className="border bg-gray-100 flex justify-center items-center h-full w-full"
                style={{ height: 80 }}
              >
                Add More Images
              </button>
            </div>
          </div>
        </div>
      </div>
      <UploadImage
        isVisible={isVisible}
        setVisible={setVisible}
        selected={selected}
        vehicleId={vehicleId}
      />
      <VimagesPanel
        isVisible={vimagePanel}
        setVisible={setVimagePanel}
        selected={selected}
        vehicleId={vehicleId}
        vimages={vehicle?.vimages}
      />
    </div>
  );
}
