import  { useEffect } from "react";
import { useNavigate } from "react-router";

export default function Manage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/manage/makes");
  }, []);
  return <div></div>;
}
