import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createVehicle = async ({ data }: any) => {
  const response = await postData({ data, uri: "vehicles" });
  return response;
};

const fetchVehicles = async ({ cursor }: any) => {
  const response = await fetchData({
    uri: `vehicles?cursor=${cursor}&m=0`,
  });
  return response;
};

const fetchVehicle = async ({ id }: any) => {
  const response = await fetchData({ uri: `vehicles/${id}?m=0` });
  return response;
};

const updateVehicle = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `vehicles/${id}` });
  return response;
};


const saleVehicle = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `vehicles/${id}/update/sale` });
  return response;
};

const updateVehicleDetails = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/details`,
  });
  return response;
};

const updateVehicleSpecs = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/specs`,
  });
  return response;
};

const updateVehicleFeatures = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/features`,
  });
  return response;
};

const updateVehicleLocation = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/location`,
  });
  return response;
};

const updateVehicleMake = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/make`,
  });
  return response;
};

const updateVehicleStatus = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/status`,
  });
  return response;
};

const updateVehiclePrice = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/price`,
  });
  return response;
};

const updateVehicleViews = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/views`,
  });
  return response;
};

const updateVehiclePublish = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/publish`,
  });
  return response;
};

const updateVehicleDeal = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `vehicles/${id}/update/deal`,
  });
  return response;
};




const uploadVehicleImage = async ({ id, file, position, category, is_main }: any) => {
  const response = await uploadFile({
    file,
    uri: `vehicles/${id}/update/images?position=${position}&category=${category}&is_main=${is_main}`,
  });
  return response;
};

const uploadVehicleFile = async ({ id, data }: any) => {
  const response = await uploadFile({
    data,
    uri: `vehicles/${id}/update/files`,
  });
  return response;
};

const deleteVehicle = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `vehicles/${id}` });
  return response;
};


const addVehicleFeature = async ({ data }: any) => {
  const response = await postData({
    data,
    uri: `features`,
  });
  return response;
};

const updateVehicleFeature = async ({ feature_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `features/${feature_id}`,
  });
  return response;
};


const deleteVehicleFeature = async ({ feature_id }: any) => {
  const response = await deleteData({
    uri: `features/${feature_id}`,
  });
  return response;
};

export {
  createVehicle,
  updateVehicle,
  fetchVehicles,
  fetchVehicle,
  deleteVehicle,
  saleVehicle,
  updateVehicleDetails,
  updateVehicleSpecs,
  updateVehicleFeatures,
  updateVehicleLocation,
  updateVehicleMake,
  updateVehiclePublish,
  updateVehicleDeal,
  updateVehicleStatus,
  updateVehiclePrice,
  updateVehicleViews,
  uploadVehicleImage,
  uploadVehicleFile,

  addVehicleFeature,
  updateVehicleFeature,
  deleteVehicleFeature
};
