import { Button, Form, Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateVehiclePublish } from "../../../../api/vehicles";

const UpdatePublish = ({
  isVisible,
  setVisible,
  is_published,
  vehicleId,
}: any) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateVehiclePublish,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const data = { is_published: !is_published };
    mutate({ id: vehicleId, data });
  };

  const action = is_published ? "Unpublish" : "Publish";

  return (
    <Modal
      title={`${action} Vehicle`}
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateVehiclePublish"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          {action}
        </Button>,
      ]}
    >
      <Form
        id="updateVehiclePublish"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          {is_published ? (
            <div className="">
              Are you Sure you want to{" "}
              <span className="text-red-600 text-bold">Unpublish</span> This
              Vehicle
            </div>
          ) : (
            <div className="">
              Are you Sure you want to{" "}
              <span className="text-green-600 text-bold">Publish</span> This
              Vehicle
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default UpdatePublish;
