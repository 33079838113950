import { NavLink } from "react-router-dom";
import Home from "../icons/Home";
import Lead from "../icons/Lead";
import Services from "../icons/Service";
import Car from "../icons/Car";
import Repor from "../icons/Repor";
import Folder from "../icons/Folder";
import Invoice from "../icons/Invoice";
import Cog from "../icons/Cog";
import Delivery from "../icons/Delivery";
import Part from "../icons/Part";

export default function SideMenu() {
  const menus = [
    {
      id: 1,
      name: "Dashboard",
      icon: <Home width={25} height={25} color={"#4b5563"} />,
      to: "/dashboard",
    },
    {
      id: 2,
      name: "CRM",
      icon: <Lead width={25} height={25} color={"#4b5563"} />,
      to: "/customers",
    },
    {
      id: 3,
      name: "Vehicles",
      icon: <Car width={25} height={25} color={"#4b5563"} />,
      to: "/vehicles",
    },
    {
      id: 6,
      name: "Proforma",
      icon: <Folder width={25} height={25} color={"#4b5563"} />,
      to: "/proforma",
    },
    {
      id: 7,
      name: "Invoices",
      icon: <Invoice width={25} height={25} color={"#4b5563"} />,
      to: "/invoices",
    },
    {
      id: 4,
      name: "Parts",
      icon: <Part width={25} height={25} color={"#4b5563"} />,
      to: "/parts",
    },

    {
      id: 19,
      name: "Orders",
      icon: <Delivery width={25} height={25} color={"#4b5563"} />,
      to: "/orders",
    },
    {
      id: 10,
      name: "Services",
      icon: <Services width={25} height={25} color={"#4b5563"} />,
      to: "/services",
    },
    {
      id: 10,
      name: "Reports",
      icon: <Repor width={25} height={25} color={"#4b5563"} />,
      to: "/reports",
    },
    {
      id: 11,
      name: "Manage",
      icon: <Cog width={25} height={25} color={"#4b5563"} />,
      to: "/manage/makes",
    },
  ];
  return (
    <div>
      {menus.map((item: any, index: number) => (
        <NavLink
          to={item?.to}
          className={({ isActive }) =>
            isActive
              ? "border-r border-gray-700 bg-gray-100  flex flex-col justify-center items-center py-3"
              : "flex flex-col justify-center items-center py-3"
          }
          key={index}
        >
          <div className="mb-1">{item?.icon}</div>
          <div className="text-xs">{item?.name}</div>
        </NavLink>
      ))}
    </div>
  );
}
