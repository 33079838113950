import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Drawer, Form, Input, Row, Space, message } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { createRegion } from "../../../../api/regions";

export default function CreateRegion({ isVisible, setVisible, selected }: any) {
  const { id } = selected;

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createRegion,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["countries", id] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = {
      count: 0,
      isActive: true,
      country_id: Number(id),
      ...values,
    };
    mutate({ data });
  };

  return (
    <Drawer
      title={`Create City / Region`}
      placement={"right"}
      width={500}
      closable={false}
      onClose={() => setVisible(false)}
      open={isVisible}
      extra={
        <Space>
          <Button danger onClick={() => setVisible(false)}>
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <Form
          id="createRegion"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="name"
                label="Region Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter region name",
                  },
                ]}
              >
                <Input placeholder="Region Name" />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="border-0 bg-blue-600 text-white mr-2"
                loading={isLoading}
              >
                Submit
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Drawer>
  );
}
