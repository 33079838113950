import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { updateCountry } from "../../../../api/countries";

const UpdateCountry = ({ isVisible, setVisible, selected }: any) => {
  const { id, name, code, flag, short } = selected;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateCountry,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["countries"] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <Modal
      title="Update Country"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateCountry"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateCountry"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Country Name"
              initialValue={name}
              rules={[
                {
                  required: true,
                  message: "Please enter country name",
                },
              ]}
            >
              <Input placeholder="Country Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="flag"
              label="Country Flag"
              initialValue={flag}
              rules={[
                {
                  required: false,
                  message: "Please enter country flag",
                },
              ]}
            >
              <Input placeholder="Country Flag" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="code"
              label="Country Code"
              initialValue={code}
              rules={[
                {
                  required: true,
                  message: "Please enter country code",
                },
              ]}
            >
              <Input placeholder="Country Code" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="short"
              label="Country Short"
              initialValue={short}
              rules={[
                {
                  required: true,
                  message: "Please enter country short",
                },
              ]}
            >
              <Input placeholder="Country Short" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateCountry;
