import { IoChevronBack } from "react-icons/io5";
import { useNavigate } from "react-router";

export default function BackToolNav() {
  const navigate = useNavigate();
  return (
    <div className="flex items-center h-8 border-b bg-gray-50">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center text-xs px-1"
      >
        <div className="">
          <IoChevronBack />
        </div>
        <div className="">Back</div>
      </button>
    </div>
  );
}
