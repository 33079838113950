import { FiBarChart2 } from "react-icons/fi";

export default function Dashboard() {
  return (
    <div className="flex flex-col justify-center items-center mt-16">
      <div className="">
        <FiBarChart2 size={40} color="#d1d5db" />
      </div>
      <div className="text-gray-400">Dashboard</div>
    </div>
  );
}
