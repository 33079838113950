import { BsBriefcase, BsClockHistory } from "react-icons/bs";
import Moment from "react-moment";
import placeholder from "../../../../assets/images/placeholder.png";
import { AiOutlineMessage } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

export default function DetailsHeader({ customer }: any) {
  return (
    <div className="grid grid-cols-12 gap-x-2 border-b pb-2">
      <div className="col-span-12 sm:col-span-4">
        <div className="flex items-center">
          <div className="h-24 w-24 border-4 rounded-full bg-gray-100 flex justify-center items-center">
            <img src={placeholder} />
          </div>
          <div className="flex flex-col ml-4">
            <div className="text-black font-bold text-3xl">
              {customer?.name}
            </div>
            <div className="">
              <span>Customer Id : #{customer?.id}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-8">
        <div className="flex items-center justify-between border-l border-gray-100 h-full pl-8">
          <div className="flex flex-col">
            <div className="flex justify-start items-center">
              <div className="mr-2">
                <BsBriefcase />
              </div>
              <div className="text-gray-500 w-24">Category :</div>
              <div className="font-bold mx-1">
                {customer?.isCompany ? "Company" : "Individual"}
              </div>
            </div>
            <div className="flex justify-start items-center mt-2">
              <div className="mr-2">
                <BsClockHistory />
              </div>
              <div className="text-gray-500 w-24">Last Seen :</div>
              <div className="font-bold mx-1">
                <Moment fromNow>{customer?.UpdatedAt}</Moment>
              </div>
            </div>
          </div>

          <div className="flex">
            <button className="flex justify-center items-center border rounded-md w-28 p-2">
              <AiOutlineMessage />
              <span className="ml-1 text-xs">Message</span>
            </button>
            <button className="flex justify-center items-center border rounded-md w-28 p-2 bg-gray-800 ml-2">
              <FiEdit color={"#FFFFFF"} />
              <span className="ml-1 text-white text-xs">Edit Details</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
