import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import {
  fetchOneOrders,
  updateOrderLast,
  updateStateStatus,
} from "../../api/orders";
import moment from "moment";
import BackToolNav from "../../components/toolnav/BackToolNav";
import { useState } from "react";
import CreateState from "./components/CreateState";
import { message } from "antd";
import { statusColors } from "../../utils/statusColors";
import UpdateState from "./components/UpdateState";
import DeleteState from "./components/DeleteState";
import UpdateStatus from "./components/UpdateStatus";
import { FiEdit } from "react-icons/fi";

export default function OneOrder({ route }: any) {
  const [createStateModal, setCreateStateModal] = useState<boolean>(false);
  const [updateStateModal, setUpdateStateModal] = useState<boolean>(false);
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false);
  const [deleteStateModal, setDeleteStateModal] = useState<boolean>(false);
  const [state, setState] = useState<any>({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: order } = useQuery({
    queryKey: ["orders", id],
    queryFn: () => fetchOneOrders({ order_id: id }),
  });

  const { mutate: updateStatus } = useMutation({
    mutationFn: updateStateStatus,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["orders", id] });
    },
  });

  const { mutate: updateLast } = useMutation({
    mutationFn: updateOrderLast,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["orders", id] });
    },
  });

  const handleUpdateStatus = async ({ status, state_id }: any) => {
    await updateStatus({ data: { status }, state_id });
  };

  const handleUpdateLast = async ({ is_last, state_id }: any) => {
    await updateLast({ data: { is_last: !is_last }, state_id });
  };

  return (
    <div className="">
      <BackToolNav />
      <div className="flex justify-between border-b p-2">
        <div className="flex">
          <div className="">ORDER</div>
          <div className="text-blue-700 mx-2">
            #{order?.id}
            {moment(order?.created_at).format("YY")}
          </div>
        </div>
        <button
          onClick={() => setUpdateStatusModal(true)}
          className="flex items-center"
          style={{ color: `${statusColors[order?.status]}` }}
        >
          <div className="mx-2">{order?.status}</div>
          <FiEdit />
        </button>
      </div>
      <div className="flex justify-between p-2">
        <div className="">State</div>
        <button onClick={() => setCreateStateModal(true)} className="">
          + Add
        </button>
      </div>

      <div className="p-2">
        {order?.orderstates?.map((state: any, index: number) => (
          <div className="flex justify-between border-b p-2" key={index}>
            <div className="">
              <div className="font-bold">{state?.title}</div>
              <div className="">{state?.description}</div>
              <div className="uppercase">
                {moment(state?.due_date).format("Do MMM YYYY")}
              </div>
              <div
                className=""
                style={{ color: `${statusColors[state?.status]}` }}
              >
                {state?.status}
              </div>
            </div>
            <div className="">
              <button
                className="mx-2"
                onClick={() =>
                  handleUpdateStatus({ status: "Pending", state_id: state?.id })
                }
              >
                Pending
              </button>
              <button
                className="mx-2"
                onClick={() =>
                  handleUpdateStatus({
                    status: "Inprogress",
                    state_id: state?.id,
                  })
                }
              >
                Start
              </button>
              <button
                className="mx-2"
                onClick={() =>
                  handleUpdateStatus({
                    status: "Completed",
                    state_id: state?.id,
                  })
                }
              >
                Completed
              </button>

              <div className="">
                <button
                  className="mx-2"
                  onClick={() =>
                    handleUpdateLast({
                      is_last: state?.is_last,
                      state_id: state?.id,
                    })
                  }
                >
                  {state?.is_last ? "YES" : "NO"}
                  Last
                </button>

                <button
                  className="mx-2"
                  onClick={() => {
                    setState(state);
                    setUpdateStateModal(true);
                  }}
                >
                  Edit
                </button>

                <button
                  className="mx-2"
                  onClick={() => {
                    setState(state);
                    setDeleteStateModal(true);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <CreateState
        isVisible={createStateModal}
        setVisible={setCreateStateModal}
        order={order}
        order_id={id}
      />

      <UpdateState
        isVisible={updateStateModal}
        setVisible={setUpdateStateModal}
        order={order}
        order_id={id}
        state={state}
        key={state}
      />

      <DeleteState
        isVisible={deleteStateModal}
        setVisible={setDeleteStateModal}
        order={order}
        order_id={id}
        state={state}
        key={state}
      />

      <UpdateStatus
        isVisible={updateStatusModal}
        setVisible={setUpdateStatusModal}
        order={order}
        orderId={id}
      />
    </div>
  );
}
