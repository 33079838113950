const positions: any = {
  e1: "EXTERIOR 1",
  e2: "EXTERIOR 2",
  e3: "EXTERIOR 3",
  e4: "EXTERIOR 4",
  i1: "INTERIOR 1",
  i2: "INTERIOR 2",
  i3: "INTERIOR 3",
  i4: "INTERIOR 4",
  m1: "MECHANICAL 1",
  m2: "MECHANICAL 2",
  m3: "MECHANICAL 3",
  m4: "MECHANICAL 4",
  o1: "OTHERS 1",
  o2: "OTHERS 2",
};

export default positions;
