import { Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiOutlineCheck } from "react-icons/ai";
import { updateProductStatus } from "../../../../api/products";

const UpdateStatus = ({ isVisible, setVisible, product, productId }: any) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateProductStatus,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
      setVisible(false);
    },
  });

  const onSubmit = async ({ status }: any) => {
    let is_for_sale = true
    switch (status) {
      case "Available":
      is_for_sale = true
      break;
      case "In Stock":
      is_for_sale = true
      break;
      case "In Transit":
      is_for_sale = false
      break;
      case "Out of stock":
      is_for_sale = false
      break;
    }
    const data = { status, is_for_sale };
    mutate({ product_id: productId, data });
  };

  const statuses = [
    { id: 1, is_sold: false, name: "Available" },
    { id: 2, is_sold: false, name: "In Stock" },
    { id: 3, is_sold: false, name: "In Transit" },
    { id: 4, is_sold: false, name: "Out of stock" },
  ];

  return (
    <Modal
      title="Update Status"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="">
        {statuses?.map((status: any, index: number) => (
          <button
            className={`flex items-center bg-gray-100 p-2 mb-2 border w-full ${
              product?.status === status?.name ? "bg-green-100" : ""
            }`}
            onClick={() =>
              onSubmit({ status: status?.name })
            }
            key={index}
          >
            <div className="flex justify-center items-center w-8 h-8 border border-gray-400">
              {product?.status === status?.name ? (
                <div className="">
                  <AiOutlineCheck size={20} />
                </div>
              ) : null}
            </div>
            <div className="ml-2">{status?.name}</div>
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default UpdateStatus;
