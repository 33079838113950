import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useEffect } from "react";
import { updateProductPrice } from "../../../../api/products";

export default function UpdatePrice({
  isVisible,
  setVisible,
  product,
  productId,
}: any) {
  const { id, price } = product;

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateProductPrice,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { price } = await form.validateFields();
    const data = {
      price: Number(price),
    };
    mutate({ product_id: id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...product });
  }, [form,product]);

  return (
    <Modal
      title="Update Price"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="UpdateProductPrice"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Update
        </Button>,
      ]}
    >
      <div className="">
        <Form
          id="UpdateProductPrice"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="price"
                label="Price Exclusive ( TZS )"
                initialValue={price}
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle price",
                  },
                ]}
              >
                <Input placeholder="Price Exclusive ( TZS )" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
