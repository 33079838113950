import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Modal, Select, message } from "antd";
import { useEffect } from "react";
import { cancelReservation } from "../../../../api/reservations";
import Moment from "react-moment";

export default function CancelReservation({
  isVisible,
  setVisible,
  vehicle,
  vehicleId,
  reservation,
}: any) {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: cancelReservation,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Canceled Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["reservations", vehicleId] });
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    await mutate({
      id: reservation?.id,
      data: { user_id: reservation?.user?.id, vehicle_id: reservation?.vehicle?.id },
    });
  };


  useEffect(() => {
    form.setFieldsValue({ ...vehicle });
  }, [vehicle]);

  return (
    <Modal
      title="Cancel Reservation"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          No
        </Button>,
        <Button
          key="submit"
          form="cancelVehicleReservation"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Yes
        </Button>,
      ]}
    >
      <div className="">
        <Form
          id="cancelVehicleReservation"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <div className="">
            Are you sure you want to <span className="text-red">Cancel</span> this reservation?
          </div>
          <div className="font-bold">This reservation Expires : -</div>
          <div className=""><Moment format="Do MMM, YYYY">{reservation?.end_date}</Moment></div>
        </Form>
      </div>
    </Modal>
  );
}
