import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { updateVehicleDetails } from "../../../../api/vehicles";
const { Option } = Select;
const UpdateDetailsOne = ({
  isVisible,
  setVisible,
  selected,
  vehicle,
  vehicleId,
}: any) => {
  const { id } = vehicle;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateVehicleDetails,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...vehicle });
  }, [vehicle]);

  return (
    <>
      <Modal
        title="Update Details"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="updateVehicleDetailsOne"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="updateVehicleDetailsOne"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="chassis"
                label="Chassis"
                initialValue={vehicle?.chassis}
                rules={[
                  {
                    required: false,
                    message: "Please enter chassis number",
                  },
                ]}
              >
                <Input placeholder="Chassis" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="myear"
                label="Man. Year"
                initialValue={vehicle?.myear}
                rules={[
                  {
                    required: false,
                    message: "Please enter country flag",
                  },
                ]}
              >
                <Input placeholder="Country Flag" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="ryear"
                label="Reg. Year"
                initialValue={vehicle?.ryear}
                rules={[
                  {
                    required: false,
                    message: "Please enter country code",
                  },
                ]}
              >
                <Input placeholder="Country Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="body_type"
                label="Body Type"
                initialValue={vehicle?.body_type}
                rules={[
                  {
                    required: false,
                    message: "Please select body type",
                  },
                ]}
              >
                <Select placeholder="Body Type">
                  <Option value="Sedan">Sedan</Option>
                  <Option value="Coupe">Coupe</Option>
                  <Option value="Sports Car">Sports Car</Option>
                  <Option value="Station Wagon">Station Wagon</Option>
                  <Option value="Hatchback">Hatchback</Option>
                  <Option value="Convertible">Convertible</Option>
                  <Option value="SUV">SUV</Option>
                  <Option value="Minivan">Minivan</Option>
                  <Option value="Pick up Truck">Pick up Truck</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="transmission"
                label="Transmission"
                initialValue={vehicle?.transmission}
                rules={[
                  {
                    required: false,
                    message: "Please select transmission",
                  },
                ]}
              >
                <Select placeholder="Transmission">
                  <Option value="Manual">Manual</Option>
                  <Option value="Automatic">Automatic</Option>
                  <Option value="Semi Automatic">Semi Automatic</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="fuel_type"
                label="Fuel Type"
                initialValue={vehicle?.fuel_type}
                rules={[
                  {
                    required: false,
                    message: "Please select fuel type",
                  },
                ]}
              >
                <Select placeholder="Model">
                  <Option value="Petrol">Petrol</Option>
                  <Option value="Diesel">Diesel</Option>
                  <Option value="Electric">Electric</Option>
                  <Option value="Electric Hybrid">Electric Hybrid</Option>
                  <Option value="Hydrogen Fuel">Hydrogen Fuel</Option>
                  <Option value="Synthetic Fuel">Synthetic Fuel</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="steering"
                label="Steering"
                initialValue={vehicle?.steering}
                rules={[
                  {
                    required: false,
                    message: "Please select steering",
                  },
                ]}
              >
                <Select placeholder="Steering">
                  <Option value="Left Hand Drive">Left Hand Drive</Option>
                  <Option value="Right Hand Drive">Right Hand Drive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="ex_color"
                label="Exterior Color"
                initialValue={vehicle?.ex_color}
                rules={[
                  {
                    required: false,
                    message: "Please enter exterior color",
                  },
                ]}
              >
                <Input placeholder="Exterior Color" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="in_color"
                label="Interior Color"
                initialValue={vehicle?.in_color}
                rules={[
                  {
                    required: false,
                    message: "Please enter interior color",
                  },
                ]}
              >
                <Input placeholder="Interior Color" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateDetailsOne;
