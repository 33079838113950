import fetchData from "../services/fetchData";


const fetchAllGenerations = async () => {
  const response = await fetchData({ uri: "generations" });
  return response;
};


export { fetchAllGenerations };
