import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addProductDetail } from "../../../../api/products";

export default function CreateDetail({
  isVisible,
  setVisible,
  productId,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: addProductDetail,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Added Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { name } = await form.validateFields();
    const data = {
      name,
      product_id: Number(productId),
    };
    mutate({ id: productId, data });
  };

  return (
    <Modal
      title="Add Detail"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="addProductDetail"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="addProductDetail"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
