import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useState } from "react";
import { Button, Popover } from "antd";
import { FiEdit, FiMoreVertical } from "react-icons/fi";
import { HiOutlineChevronDown } from "react-icons/hi";
import { RiDeleteBin4Line, RiEditBoxLine } from "react-icons/ri";
import placeholder from "../../../assets/images/placeholder.png";
import UpdateBranch from "./components/UpdateBranch";
import BackToolNav from "../../../components/toolnav/BackToolNav";
import { fetchBranch } from "../../../api/branches";
import Something from "../../../components/common/Something";
import ManageWrapper from "..";

export default function OneBranch() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [updateMenuModal, setUpdateMenuModal] = useState<boolean>(false);
  const [deleteMenuModal, setDeleteMenuModal] = useState<boolean>(false);
  const { id } = useParams();
  const { data: branch, isError } = useQuery({
    queryKey: ["branches", id],
    queryFn: () => fetchBranch({ id }),
  });

  return (
    <ManageWrapper
      title={"Branch"}
      subtitle={"Branch details and Menus"}
      actions={
        <div className="flex items-center">
          <div className="mr-2">
            <div className="flex items-center justify-center">
              <div className="">
                {branch?.is_active ? (
                  <div className="p-1 text-green-600 rounded-md text-xs">
                    OPEN
                  </div>
                ) : (
                  <div className="p-1  text-red-600 rounded-md text-xs">
                    CLOSED
                  </div>
                )}
              </div>
              <div className="">
                <HiOutlineChevronDown
                  color={`${branch?.is_active ? "#16a34a" : "#dc2626"}`}
                />
              </div>
            </div>
          </div>
          <Button onClick={() => setUpdateModal(true)}>
            <FiEdit />
          </Button>
        </div>
      }
      toolnav={<BackToolNav />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <div className="">
            {/* <div className="flex justify-between border-b mb-2">
              <div className="flex flex-col">
                <div className="font-bold text-xl uppercase">{data?.name}</div>
                <div className="font-thin">Branch Details</div>
              </div>
              <div className="">Active</div>
            </div> */}
            <div className="grid grid-cols-12 gap-x-2">
              <div className="col-span-12 sm:col-span-4 bg-gray-100">
                <img
                  src={branch?.image || placeholder}
                  width={"100%"}
                  className="aspect-[4/3] object-fill"
                />
              </div>
              <div className="col-span-12 sm:col-span-8">
                <div className="grid grid-cols-12 gap-x-2">
                  <div className="col-span-12 sm:col-span-6">
                    <div className="flex flex-col">
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          <div className="font-thin">Branch</div>
                          <div className="font-bold uppercase">
                            {branch?.name} Branch
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col mt-4">
                        <div className="font-thin">Email Address</div>
                        <div className="font-bold">{branch?.email || "-"}</div>
                      </div>
                      <div className="flex flex-col mt-4">
                        <div className="font-thin">Mobile Number</div>
                        <div className="font-bold">{branch?.mobile || "-"}</div>
                      </div>

                      <div className="flex flex-col mt-4">
                        <div className="font-thin">Street</div>
                        <div className="font-bold">{branch?.street || "-"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="font-thin">Postal Code</div>
                        <div className="font-bold uppercase">
                          {branch?.postal_code || "-"}
                        </div>
                      </div>

                      <div className="flex flex-col mt-4">
                        <div className="font-thin">Plot Number</div>
                        <div className="font-bold">
                          {branch?.plot_no || "-"}
                        </div>
                      </div>
                      <div className="flex flex-col mt-4">
                        <div className="font-thin">House Number</div>
                        <div className="font-bold">
                          {branch?.house_no || "-"}
                        </div>
                      </div>

                      <div className="flex flex-col mt-4">
                        <div className="font-thin">Coordinates</div>
                        <div className="font-bold">
                          {branch?.latitude || "-"}, {branch?.longitude || "-"}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center bg-gray-100 mt-4 p-2 border-b">
              <div className="">Menu</div>
            </div>
            <div className="border-t-4 mb-1"></div>
            <div className="grid grid-cols-12 gap-2">
              {branch?.menus?.map((item: any, index: number) => (
                <div className="col-span-12 sm:col-span-3 border" key={index}>
                  <div className="w-full flex justify-end items-center">
                    <div className="-mb-10 z-50">
                      <Popover
                        content={
                          <>
                            <div className="border-t"></div>
                            <button
                              onClick={() => {
                                setUpdateMenuModal(true);
                                setSelected(item);
                              }}
                              className="flex items-center hover:bg-gray-100 p-2 cursor-pointe w-full"
                            >
                              <div className="">
                                <RiEditBoxLine size={15} />
                              </div>
                              <div className="ml-2">Edit</div>
                            </button>
                            <button
                              onClick={() => {
                                setDeleteMenuModal(true);
                                setSelected(item);
                              }}
                              className="flex items-center hover:bg-gray-100 p-2 cursor-pointer w-full"
                            >
                              <div className="">
                                <RiDeleteBin4Line />
                              </div>
                              <div className="ml-2">Delete</div>
                            </button>
                          </>
                        }
                        title={`${item?.name}`}
                        placement="bottom"
                      >
                        <FiMoreVertical />
                      </Popover>
                    </div>
                  </div>
                  <div className="bg-gray-50 border-b">
                    <img
                      src={placeholder}
                      width={"100%"}
                      className="aspect-[4/3] object-fill"
                    />
                  </div>
                  <div className="p-2">
                    <div className="">{item?.name}</div>
                    <div className="truncate text-gray-500 text-xs">
                      {item?.description}
                    </div>
                    <div className="flex justify-between">
                      <div className="">{item?.price} /=</div>
                      <div className="">
                        {item?.is_active ? (
                          <div className="p-1 text-green-600 rounded-md text-xs">
                            Available
                          </div>
                        ) : (
                          <div className="p-1  text-red-600 rounded-md text-xs">
                            Not Available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <UpdateBranch
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={branch || {}}
        />
      </div>
    </ManageWrapper>
  );
}
