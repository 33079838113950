import {  useState } from "react";
import placeholder from "../../../../assets/images/placeholder.png";
import UploadImage from "./UploadImage";
import imagesPositions from "../../../../utils/imagesPositions";
export default function ProductImages({ product, editable, productId }: any) {
  const { a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12 } = imagesPositions({
    product,
    placeholder,
  });
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  return (
    <div className="grid grid-cols-12 gap-2  p-2">
      <div className="col-span-12 sm:col-span-6 bg-gray-100">
        <button
          onClick={() => {
            if (editable) {
              setSelected({ position: "a1", category: "standard" });
              setVisible(true);
            }
          }}
          className=""
        >
          <img src={a1} width={450} />
        </button>
      </div>
      <div className="col-span-12 sm:col-span-6">
        <div className="flex justify-between mb-1">
          <div className="">
          <div className="text-center bg-gray-200 border-b-2 border-gray-300 mb-1">
              STANDARD "A" IMAGES
            </div>

            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a1", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a1} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a2", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a2} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a3", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a3} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a4", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a4} width={150} />
                </button>
              </div> 
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-1">
          <div className="">

            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a5", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a5} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a6", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a6} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a7", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a7} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a8", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a8} width={150} />
                </button>
              </div> 
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="">
            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a9", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a9} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a10", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a10} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a11", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a11} width={150} />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "a12", category: "standard" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={a12} width={150} />
                </button>
              </div> 
            </div>
          </div>
        </div>

      </div>
      <UploadImage
        isVisible={isVisible}
        setVisible={setVisible}
        selected={selected}
        productId={productId}
      />
    </div>
  );
}
