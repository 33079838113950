import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { fetchCountries } from "../../../../api/countries";
import { updateVehicleLocation } from "../../../../api/vehicles";

const { Option } = Select;

const UpdateLocation = ({ isVisible, setVisible, vehicle, vehicleId }: any) => {
  const { country_id, region_id } = vehicle;
  const queryClient = useQueryClient();
  const [regions, setRegions] = useState<any>([]);

  const [form] = Form.useForm();

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: fetchCountries,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: updateVehicleLocation,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const handleRegions = ({ id }: any) => {
    const country = countries?.filter((country: any) => country.id === id);
    setRegions(country[0]?.regions);
  };

  const countryOptions = countries?.map((country: any, index: number) => (
    <Option value={country?.id} key={index}>
      {country?.name}
    </Option>
  ));

  const regionOptions = regions?.map((region: any, index: number) => (
    <Option value={region?.id} key={index}>
      {region?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    mutate({ id: vehicleId, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...vehicle });

    if (country_id) {
      handleRegions({ id: country_id });
    }
  }, [vehicle]);

  return (
    <Modal
      title="Update Location"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateVehicleLocation"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleLocation"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="country_id"
              label="Country"
              initialValue={country_id}
              rules={[
                {
                  required: true,
                  message: "Please select country",
                },
              ]}
            >
              <Select
                placeholder="Country"
                defaultValue={country_id}
                onSelect={(e: any) => handleRegions({ id: e })}
              >
                {countryOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="region_id"
              label="Region / City"
              initialValue={region_id}
              rules={[
                {
                  required: true,
                  message: "Please enter region",
                },
              ]}
            >
              <Select placeholder="Region / City" defaultValue={region_id}>
                {regionOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateLocation;
