import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCountry } from "../../../../api/countries";

const CreateCountry: React.FC = () => {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createCountry,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["countries"] });
      setOpen(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { count: 0, ...values };
    mutate({ data });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      <Modal
        title="Create Country"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="createCountry"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createCountry"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Country Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter country name",
                  },
                ]}
              >
                <Input placeholder="Country Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="flag"
                label="Country Flag"
                rules={[
                  {
                    required: true,
                    message: "Please enter country flag",
                  },
                ]}
              >
                <Input placeholder="Country Flag" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="code"
                label="Country Code"
                rules={[
                  {
                    required: true,
                    message: "Please enter country code",
                  },
                ]}
              >
                <Input placeholder="Country Code" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="short"
                label="Country Short"
                rules={[
                  {
                    required: true,
                    message: "Please enter country short",
                  },
                ]}
              >
                <Input placeholder="Country Short" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCountry;
