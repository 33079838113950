import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createCustomer = async ({ data }: any) => {
  const response = await postData({ data, uri: "customers" });
  return response;
};

const fetchCustomers = async () => {
  const response = await fetchData({ uri: "customers" });
  return response;
};

const fetchCustomer = async ({ id }: any) => {
  const response = await fetchData({ uri: `customers/${id}` });
  return response;
};

const updateCustomer = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `customers/${id}` });
  return response;
};

const deleteCustomer = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `customers/${id}` });
  return response;
};

export {
  createCustomer,
  updateCustomer,
  fetchCustomers,
  fetchCustomer,
  deleteCustomer,
};
