import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Something from "../../../components/common/Something";
import { useParams } from "react-router";
import BackToolNav from "../../../components/toolnav/BackToolNav";
import { useEffect } from "react";
import { Button } from "antd";
import { FiEdit } from "react-icons/fi";
import ManageWrapper from "..";
import { fetchVehicle, updateVehicleViews } from "../../../api/vehicles";
import VehicleHeader from "./components/VehicleHeader";
import VehicleImages from "./components/VehicleImages";
import VehicleDetailsOne from "./components/VehicleDetailsOne";
import VehicleDetailsTwo from "./components/VehicleDetailsTwo";
import PublishWidget from "./components/PublishWidget";
import PriceWidget from "./components/PriceWidget";
import { RiDeleteBin4Line } from "react-icons/ri";
import DealWidget from "./components/DealWidget";
import Reservations from "./components/Reservations";
import VehicleFeatures from "./components/VehicleFeatures";

export default function OneVehicle() {
  const { id } = useParams();

  const queryClient = useQueryClient();

  const { data: vehicle, isError } = useQuery({
    queryKey: ["vehicles", id],
    queryFn: () => fetchVehicle({ id }),
  });

  const { mutate: updateViews } = useMutation({
    mutationFn: updateVehicleViews,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["vehicles", id] });
    },
  });

  useEffect(() => {
    updateViews({ id, data: { views: 21 } });
  }, []);

  return (
    <ManageWrapper
      title={"Vehicle"}
      subtitle={"Vehicle details"}
      actions={
        <div className="flex items-center">
          <Button>
            <FiEdit />
          </Button>

          <Button
            className="border bg-red-100 ml-2"
          >
            <RiDeleteBin4Line />
          </Button>
        </div>
      }
      toolnav={<BackToolNav />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 sm:col-span-9 border">
                <VehicleHeader
                  vehicle={vehicle}
                  editable={true}
                  vehicleId={id}
                />
                <VehicleImages
                  vehicle={vehicle}
                  editable={true}
                  vehicleId={id}
                />
                <div className="grid grid-cols-12">
                  <div className="col-span-12 sm:col-span-6">
                    <VehicleDetailsOne
                      vehicle={vehicle}
                      editable={true}
                      vehicleId={id}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <VehicleDetailsTwo
                      vehicle={vehicle}
                      editable={true}
                      vehicleId={id}
                    />
                  </div>
                </div>

                <div className="">
                  <VehicleFeatures
                    vehicle={vehicle}
                    vehicleId={id}
                    editable={true}
                  />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3 border">
                <PublishWidget
                  is_published={vehicle?.is_published}
                  vehicleId={id}
                />
                <DealWidget vehicleId={id} is_on_sale={vehicle?.is_on_sale} />
                <PriceWidget vehicle={vehicle} vehicleId={id} />
                <Reservations vehicle={vehicle} vehicleId={id} />
              </div>
            </div>
          </>
        )}
      </div>
    </ManageWrapper>
  );
}
