import { useState } from "react";
import { FiEdit, FiPlus } from "react-icons/fi";
import CreateSpec from "./CreateSpec";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { RiDeleteBinLine } from "react-icons/ri";
import { updateProductSpec , deleteProductSpec} from "../../../../api/products";

export default function ProductSpecs({ product, productId, editable }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutate: updateSpec } = useMutation({
    mutationFn: updateProductSpec,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
    },
  });


  const { mutate: deleteSpec } = useMutation({
    mutationFn: deleteProductSpec,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
    },
  });

  const onUpdate = async ({ id, value }: any) => {
    const data = { value: !value };
    await updateSpec({ spec_id: id, data });
  };

  const onDelete = async ({ id }: any) => {
    await deleteSpec({ spec_id: id });
  };

  return (
    <div className="">
      <div className="flex justify-between items-center border-b bg-gray-100 p-2">
        <div className="">Product Specs</div>
        {editable && (
          <button onClick={() => setCreateModal(true)} className="">
            <FiPlus />
          </button>
        )}
      </div>

      <div className="grid grid-cols-12">
        {product?.specs?.map((spec: any, index: number) => (
          <Spec
            id={spec?.id}
            title={spec?.name}
            value={spec?.value}
            onUpdate={onUpdate}
            onDelete={onDelete}
            index={index}
          />
        ))}
      </div>
      <CreateSpec
        isVisible={createModal}
        setVisible={setCreateModal}
        product={product}
        productId={productId}
      />
    </div>
  );
}

function Spec({ id, title, value, onUpdate, onDelete, index }: any) {
  return (
    <button
      className={` relative col-span-6 border flex flex-col justify-center items-start m-1 p-2`}
      key={index}
    >
      <button
        className="absolute top-0 right-0 z-50 bg-gray-100 w-6 h-6 flex items-center justify-center border-b border-l border-gray-200 hover:bg-red-200 hover:text-red-500"
        onClick={() => onDelete({id})}
      >
        <RiDeleteBinLine color="gray" />
      </button>
      <button
        className="absolute top-0 right-6 z-50 bg-gray-100 w-6 h-6 flex items-center justify-center border-b border-l border-gray-200 hover:bg-yellow-200 hover:text-yellow-500"
        onClick={() => onUpdate({ id, value })}
      >
        <FiEdit color="gray" />
      </button>
      <div
        className="flex items-center"
        
      >
        <div className="font-bold">{title} : </div>
       <div className="">{value}</div>
      </div>
    </button>
  );
}
