import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { updateCategory } from "../../../../api/categories";

const UpdateCategory = ({ isVisible, setVisible, selected }: any) => {
  const { id, name, count, index } = selected;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateCategory,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <>
      <Modal
        title="Update Category"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="updateCategory"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="updateCategory"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Name"
                initialValue={name}
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="count"
                label="Count"
                initialValue={count}
                rules={[
                  {
                    required: false,
                    message: "Please enter count",
                  },
                ]}
              >
                <Input placeholder="Count" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="index"
                label="Index"
                initialValue={index}
                rules={[
                  {
                    required: true,
                    message: "Please enter index",
                  },
                ]}
              >
                <Input placeholder="Index" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCategory;
