import { BASE_API_URL } from "../utils/urls";

const deleteData = async ({ data, uri }: any) => {
  const response = await fetch(`${BASE_API_URL}/${uri}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 200) {
    return Promise.reject("Something went wrong");
  }
  return response.json();
};

export default deleteData;
