import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { updateProductDescription } from "../../../../api/products";

const {TextArea} = Input

export default function UpdateDescription({
  isVisible,
  setVisible,
  description,
  productId,
}: any) {

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateProductDescription,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { description } = await form.validateFields();
    const data = {
      description
    };
    mutate({ product_id: productId, data });
  };

  return (
    <Modal
      title="Update Description"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="UpdateProductDescription"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Update
        </Button>,
      ]}
    >
      <div className="">
        <Form
          id="UpdateProductDescription"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="description"
                label="Product Description"
                initialValue={description}
                rules={[
                  {
                    required: true,
                    message: "Please enter product description",
                  },
                ]}
              >
                <TextArea placeholder="Product Description" style={{height: 100}} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
