import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createBranch = async ({ data }: any) => {
  const response = await postData({ data, uri: "branches" });
  return response;
};

const fetchBranches = async () => {
  const response = await fetchData({ uri: "branches" });
  return response;
};

const fetchBranch = async ({ id }: any) => {
  const response = await fetchData({ uri: `branches/${id}` });
  return response;
};

const updateBranch = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `branches/${id}` });
  return response;
};


const uploadImage = async ({ id, file, name }: any) => {
  const response = await uploadFile({
    file,
    uri: `branches/${id}/image?name=${name}`,
  });
  return response;
};

const deleteBranch = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `branches/${id}` });
  return response;
};

export {
  createBranch,
  updateBranch,
  fetchBranches,
  fetchBranch,
  deleteBranch,
  uploadImage,
};
