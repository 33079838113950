import { Button, Drawer, Form, Space, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiOutlineClose } from "react-icons/ai";
import { deleteRegion } from "../../../../api/regions";

const DeleteCountry = ({ isVisible, setVisible, selected }: any) => {
  const { id, name, country_id } = selected;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: deleteRegion,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["customers", country_id] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    mutate({ id });
  };

  return (
    <Drawer
      title={`Update City / Region`}
      placement={"right"}
      width={500}
      closable={false}
      onClose={() => setVisible(false)}
      open={isVisible}
      extra={
        <Space>
          <Button danger onClick={() => setVisible(false)}>
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="deleteCountry"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col justify-center items-center">
          <div className="">
            Are you sure you want to
            <span className="text-red-600 px-1 font-bold">Delete</span>Region
          </div>
          <div className="font-bold">{name} ?</div>
        </div>

        <div className="flex justify-center mt-8">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="border-0 bg-red-600 text-white mr-2"
              loading={isLoading}
            >
              Delete
            </Button>
            <Button
              htmlType="button"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default DeleteCountry;
