import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCountries } from "../../../../api/countries";
import { createSupplier } from "../../../../api/suppliers";
const { Option } = Select;

const CreateSupplier: React.FC = () => {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createSupplier,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["suppliers"] });
      setOpen(false);
    },
  });

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: fetchCountries,
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    mutate({ data });
  };

  const countryOptions = countries?.map((country: any, index: number) => (
    <Option value={country?.id}>
      {country?.flag} {country?.name}
    </Option>
  ));

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      <Modal
        title="Create Supplier"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="createSupplier"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createSupplier"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile",
                  },
                ]}
              >
                <Input placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: false,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: false,
                    message: "Please select country",
                  },
                ]}
              >
                {/* <Select placeholder="Country">{countryOptions}</Select> */}
                <Input placeholder="Country" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: false,
                    message: "Please enter city",
                  },
                ]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="street"
                label="Street"
                rules={[
                  {
                    required: false,
                    message: "Please select street",
                  },
                ]}
              >
                {/* <Select placeholder="Country">{countryOptions}</Select> */}
                <Input placeholder="Street" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="ward"
                label="Ward"
                rules={[
                  {
                    required: false,
                    message: "Please enter ward",
                  },
                ]}
              >
                <Input placeholder="Ward" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="postal"
                label="Postal"
                rules={[
                  {
                    required: false,
                    message: "Please select postal",
                  },
                ]}
              >
                <Input placeholder="Postal" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="zipcode"
                label="Zip Code"
                rules={[
                  {
                    required: false,
                    message: "Please enter zip code",
                  },
                ]}
              >
                <Input placeholder="Zip Code" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="plot"
                label="Plot"
                rules={[
                  {
                    required: false,
                    message: "Please select plot",
                  },
                ]}
              >
                <Input placeholder="Plot" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="fax"
                label="Fax"
                rules={[
                  {
                    required: false,
                    message: "Please select fax",
                  },
                ]}
              >
                <Input placeholder="Fax" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="block"
                label="Block"
                rules={[
                  {
                    required: false,
                    message: "Please enter block",
                  },
                ]}
              >
                <Input placeholder="Block" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="house"
                label="House"
                rules={[
                  {
                    required: false,
                    message: "Please select house",
                  },
                ]}
              >
                <Input placeholder="House" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="website"
                label="Website"
                rules={[
                  {
                    required: false,
                    message: "Please select website",
                  },
                ]}
              >
                <Input placeholder="Website" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateSupplier;
