import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const fetchAllOrders = async ({cursor}: any) => {
  const response = await fetchData({ uri: `orders?cursor=${cursor}` });
  return response;
};

const fetchOneOrders = async ({order_id}: any) => {
  const response = await fetchData({ uri: `orders/${order_id}` });
  return response;
};

const createOrderState = async ({ data }: any) => {
  const response = await postData({ data, uri: `states` });
  return response;
};

const updateOrderState = async ({ data, state_id }: any) => {
  const response = await patchData({ data, uri: `states/${state_id}` });
  return response;
};


const deleteOrderState = async ({ state_id }: any) => {
  const response = await deleteData({ uri: `states/${state_id}` });
  return response;
};


const updateStateStatus = async ({ data, state_id }: any) => {
  const response = await patchData({ data, uri: `states/${state_id}/status` });
  return response;
};

const updateOrderStatus = async ({ data, order_id }: any) => {
  const response = await patchData({ data, uri: `orders/${order_id}/status` });
  return response;
};

const updateOrderLast = async ({ data, state_id }: any) => {
  const response = await patchData({ data, uri: `states/${state_id}/last` });
  return response;
};

export {  fetchAllOrders, fetchOneOrders, createOrderState, updateOrderState, deleteOrderState,updateStateStatus, updateOrderStatus, updateOrderLast };
