import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createMake = async ({ data }: any) => {
  const response = await postData({ data, uri: "makes" });
  return response;
};

const fetchMakes = async () => {
  const response = await fetchData({ uri: "makes" });
  return response;
};

const fetchMakeModels = async ({ id }: any) => {
  const response = await fetchData({ uri: `makes/${id}` });
  return response;
};

const updateMake = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `makes/${id}` });
  return response;
};

const uploadImage = async ({ id, file, make }: any) => {
  const response = await uploadFile({ file, uri: `makes/${id}/image?make=${make}` });
  return response;
};

const deleteMake = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `makes/${id}` });
  return response;
};

export {
  createMake,
  updateMake,
  uploadImage,
  fetchMakes,
  fetchMakeModels,
  deleteMake,
};
