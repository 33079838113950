import { BASE_API_URL } from "../utils/urls";

const fetchData = async ({ uri }: any) => {
  const response = await fetch(`${BASE_API_URL}/${uri}`, {
    method: "GET",
  });

  if (response.status !== 200) {
    return Promise.reject();
  }

  return response.json();
};

export default fetchData;
