import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createDiscount } from "../../../../api/discounts";
const { TextArea } = Input;

const CreateDiscount: React.FC = () => {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createDiscount,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["discounts"] });
      setOpen(false);
    },
  });

  const onSubmit = async () => {
    const { name, percent, description } = await form.validateFields();
    const data = { name, percent: Number(percent), description };
    mutate({ data });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      <Modal
        title="Create Discount"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="createDiscount"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createDiscount"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="percent"
                label="Percentage"
                rules={[
                  {
                    required: true,
                    message: "Please enter percentage",
                  },
                ]}
              >
                <Input placeholder="Percentage" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: false,
                    message: "Please enter description",
                  },
                ]}
              >
                <TextArea placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateDiscount;
