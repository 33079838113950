import { useState } from "react";
import { FiEdit, FiPlus } from "react-icons/fi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { RiDeleteBinLine } from "react-icons/ri";
import {  deleteProductFitment, updateProductFitment} from "../../../../api/products";
import CreateFitment from "./CreateFitment";

export default function ProductFitments({ product, productId, editable }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutate: updateFitment } = useMutation({
    mutationFn: updateProductFitment,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
    },
  });


  const { mutate: deleteFitment } = useMutation({
    mutationFn: deleteProductFitment,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
    },
  });

  const onUpdate = async ({ id, title, description }: any) => {
    const data = { title, description };
    await updateFitment({ fitment_id: id, data });
  };

  const onDelete = async ({ id }: any) => {
    await deleteFitment({ fitment_id: id });
  };

  return (
    <div className="">
      <div className="flex justify-between items-center border-b bg-gray-100 p-2">
        <div className="">Product Fitments</div>
        {editable && (
          <button onClick={() => setCreateModal(true)} className="">
            <FiPlus />
          </button>
        )}
      </div>

      <div className="grid grid-cols-12">
        {product?.fitments?.map((fitment: any, index: number) => (
          <Fitment
            id={fitment?.id}
            title={fitment?.title}
            description={fitment?.description}
            onUpdate={onUpdate}
            onDelete={onDelete}
            index={index}
          />
        ))}
      </div>
      <CreateFitment
        isVisible={createModal}
        setVisible={setCreateModal}
        product={product}
        productId={productId}
      />
    </div>
  );
}

function Fitment({ id, title, description, onUpdate, onDelete, index }: any) {
  return (
    <button
      className={` relative col-span-12 border flex flex-col justify-center items-start m-1 p-2`}
      key={index}
    >
      <button
        className="absolute top-0 right-0 z-50 bg-gray-100 w-6 h-6 flex items-center justify-center border-b border-l border-gray-200 hover:bg-red-200 hover:color-red-500"
        onClick={() => onDelete({id})}
      >
        <RiDeleteBinLine color="gray" />
      </button>
      <button
        className="absolute top-0 right-6 z-50 bg-gray-100 w-6 h-6 flex items-center justify-center border-b border-l border-gray-200 hover:bg-yellow-200 hover:color-yellow-500"
        onClick={() => onUpdate({ id, title, description })}
      >
        <FiEdit color="gray" />
      </button>
      <div
        className="flex flex-col items-start"
        
      >
       <div className="text-justify">{description}</div>
      </div>
    </button>
  );
}
