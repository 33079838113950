import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createRegion = async ({ data }: any) => {
  const response = await postData({ data, uri: "regions" });
  return response;
};

const fetchRegions = async () => {
  const response = await fetchData({ uri: "regions" });
  return response;
};

const updateRegion = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `regions/${id}` });
  return response;
};

const deleteRegion = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `regions/${id}` });
  return response;
};

export { createRegion, updateRegion, fetchRegions, deleteRegion };
