import { Button, Drawer, Space } from "antd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ExteriorVimages from "./ExteriorVimages";
import InteriorVimages from "./InteriorVimages";
import MechanicalVimages from "./MechanicalVimages";
import OtherVimages from "./OtherVimages";
import { getImageCategories } from "../../../../utils/getImageCategories";

export default function VimagesPanel({ isVisible, setVisible, vimages, vehicleId }: any) {
  const [selected, setSelected] = useState<string>("exterior");
  const [categories, setCategories] = useState<any>([
    { id: 1, name: "Exterior", type: "exterior" },
    { id: 1, name: "Interior", type: "interior" },
    { id: 1, name: "Mechanical", type: "mechanical" },
    { id: 1, name: "Others", type: "others" },
  ]);

  
  const data: any =  getImageCategories({images: vimages})


  const getWidget = (data: any) => {
    let widget = <ExteriorVimages images={data?.exterior || []} vehicleId={vehicleId} />;
    switch (selected) {
      case "exterior":
        widget = <ExteriorVimages  images={data?.exterior || []} vehicleId={vehicleId}/>;
        break;
      case "interior":
        widget = <InteriorVimages  images={data?.interior || []} vehicleId={vehicleId} />;
        break;
      case "mechanical":
        widget = <MechanicalVimages  images={data?.mechanical || []} vehicleId={vehicleId}/>;
        break;
        case "others":
          widget = <OtherVimages  images={data?.others || []} vehicleId={vehicleId}/>;
          break;
    }
    return widget
  };

  const widget = getWidget(data)
  return (
    <Drawer
      title={`VEHICLE IMAGES`}
      placement={"right"}
      width={800}
      closable={false}
      onClose={() => setVisible(false)}
      open={isVisible}
      extra={
        <Space>
          <Button danger onClick={() => setVisible(false)}>
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <div className="flex justify-start">
          {categories?.map((category: any, index: number) => (
            <button
              onClick={() => setSelected(category?.type)}
              className={`mr-10 border-b-4 ${
                selected === category?.type ? "border-black" : ""
              }`}
              key={index}
            >
              {category?.name}
            </button>
          ))}
        </div>
      </div>
      <div className="pt-8">
        {widget}
      </div>
    </Drawer>
  );
}
