import { Route, Routes } from "react-router";
import "./App.css";
import Dashboard from "./pages/home";
import NotFound from "./pages/errors/NotFound";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MainLayout from "./layouts/MainLayout";
import AllMakes from "./pages/manage/makes/AllMakes";
import AllBranches from "./pages/manage/branches/AllBranches";
import AllCountries from "./pages/manage/countries/AllCountries";
import Manage from "./pages/manage/Manage";
import OneMake from "./pages/manage/makes/OneMake";
import OneBranch from "./pages/manage/branches/OneBranch";
import AllCustomers from "./pages/manage/customers/AllCustomers";
import OneCustomer from "./pages/manage/customers/OneCustomer";
import AllVehicles from "./pages/manage/vehicles/AllVehicles";
import OneVehicle from "./pages/manage/vehicles/OneVehicle";
import AllInquiries from "./pages/manage/inquiries/AllInquiries";
import AllSellers from "./pages/manage/sellers/AllSellers";
import OneSeller from "./pages/manage/sellers/OneSeller";
import AllSuppliers from "./pages/manage/suppliers/AllSuppliers";
import OneSupplier from "./pages/manage/suppliers/OneSupplier";
import AllOptions from "./pages/manage/options/AllOptions";
import AllProducts from "./pages/manage/products/AllProducts";
import OneProduct from "./pages/manage/products/OneProduct";
import AllPosts from "./pages/manage/posts/AllPosts";
import OnePost from "./pages/manage/posts/OnePost";
import AllBrands from "./pages/manage/brands/AllBrands";
import OneBrand from "./pages/manage/brands/OneBrand";
import AllDiscounts from "./pages/manage/discounts/AllDiscounts";
import AllInventories from "./pages/manage/inventories/AllInventories";
import AllReviews from "./pages/manage/reviews/AllReviews";
import AllCategories from "./pages/manage/categories/AllCategories";
import AllOrders from "./pages/orders/AllOrders";
import OneOrder from "./pages/orders/OneOrder";
import SignIn from "./pages/auth/SignIn";
import AuthLayout from "./layouts/AuthLayout";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<SignIn />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/orders" element={<AllOrders />} />
          <Route path="/orders/:id" element={<OneOrder />} />

          {/* Manage */}
          <Route path="/manage" element={<Manage />} />

          <Route path="/manage/customers" element={<AllCustomers />} />
          <Route path="/manage/customers/:id" element={<OneCustomer />} />

          <Route path="/manage/vehicles" element={<AllVehicles />} />
          <Route path="/manage/vehicles/:id" element={<OneVehicle />} />

          <Route path="/manage/inquiries" element={<AllInquiries />} />

          <Route path="/manage/makes" element={<AllMakes />} />
          <Route path="/manage/makes/:id" element={<OneMake />} />
          <Route path="/manage/countries" element={<AllCountries />} />
          <Route path="/manage/branches" element={<AllBranches />} />
          <Route path="/manage/branches/:id" element={<OneBranch />} />

          <Route path="/manage/options" element={<AllOptions />} />

          <Route path="/manage/suppliers" element={<AllSuppliers />} />
          <Route path="/manage/suppliers/:id" element={<OneSupplier />} />

          <Route path="/manage/sellers" element={<AllSellers />} />
          <Route path="/manage/sellers/:id" element={<OneSeller />} />

          {/* PRODUCT RELATED */}

          <Route path="/manage/products" element={<AllProducts />} />
          <Route path="/manage/products/:id" element={<OneProduct />} />

          <Route path="/manage/posts" element={<AllPosts />} />
          <Route path="/manage/posts/:id" element={<OnePost />} />

          <Route path="/manage/brands" element={<AllBrands />} />
          <Route path="/manage/brands/:id" element={<OneBrand />} />

          <Route path="/manage/categories" element={<AllCategories />} />
          <Route path="/manage/products/:id" element={<OneProduct />} />

          <Route path="/manage/discounts" element={<AllDiscounts />} />
          <Route path="/manage/inventories" element={<AllInventories />} />
          <Route path="/manage/reviews" element={<AllReviews />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
