import { Button, Checkbox, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBranch } from "../../../../api/branches";
import { useEffect, useState } from "react";

const UpdateBranch = ({ isVisible, setVisible, selected }: any) => {
  const [isActive, setActive] = useState<boolean>(selected?.is_active);
  const { id } = selected;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateBranch,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["branches"] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { is_active: isActive, ...values };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <>
      <Modal
        title="Update Branch"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="updateBranch"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="updateBranch"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Name"
                initialValue={selected?.name}
                rules={[
                  {
                    required: true,
                    message: "Please enter branch name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="street"
                label="Street"
                initialValue={selected?.street}
                rules={[
                  {
                    required: true,
                    message: "Please enter branch street",
                  },
                ]}
              >
                <Input placeholder="Street" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="email"
                label="Email"
                initialValue={selected?.email}
                rules={[
                  {
                    required: true,
                    message: "Please enter branch email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="mobile"
                label="Mobile"
                initialValue={selected?.mobile}
                rules={[
                  {
                    required: true,
                    message: "Please enter branch mobile",
                  },
                ]}
              >
                <Input placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="postal_code"
                label="Postal Code"
                initialValue={selected?.postal_code}
                rules={[
                  {
                    required: true,
                    message: "Please enter branch postal code",
                  },
                ]}
              >
                <Input placeholder="Postal Code" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="plot_no"
                label="Plot No."
                initialValue={selected?.plot_no}
                rules={[
                  {
                    required: true,
                    message: "Please enter Plot No.",
                  },
                ]}
              >
                <Input placeholder="Plot Number" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="house_no"
                label="House No."
                initialValue={selected?.house_no}
                rules={[
                  {
                    required: true,
                    message: "Please enter house number",
                  },
                ]}
              >
                <Input placeholder="House No." />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Checkbox
                defaultChecked={selected?.is_active}
                onChange={() => setActive(!isActive)}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateBranch;
