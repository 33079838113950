import { BsCheck2 } from "react-icons/bs";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import CreateFeature from "./CreateFeature";
import { deleteVehicleFeature, updateVehicleFeature } from "../../../../api/vehicles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { RiDeleteBinLine } from "react-icons/ri";

export default function VehicleFeatures({ vehicle, vehicleId, editable }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutate: updateFeature } = useMutation({
    mutationFn: updateVehicleFeature,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
    },
  });


  const { mutate: deleteFeature } = useMutation({
    mutationFn: deleteVehicleFeature,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
    },
  });

  const onUpdate = async ({ id, value }: any) => {
    const data = { value: !value };
    await updateFeature({ feature_id: id, data });
  };

  const onDelete = async ({ id }: any) => {
    await deleteFeature({ feature_id: id });
  };

  return (
    <div className="">
      <div className="flex justify-between items-center border-b bg-gray-100 p-2">
        <div className="">Vehicle Details</div>
        {editable && (
          <button onClick={() => setCreateModal(true)} className="">
            <FiPlus />
          </button>
        )}
      </div>

      <div className="grid grid-cols-12">
        {vehicle?.features?.map((feature: any, index: number) => (
          <Feature
            id={feature?.id}
            title={feature?.name}
            value={feature?.value}
            onUpdate={onUpdate}
            onDelete={onDelete}
            key={index}
          />
        ))}
      </div>
      <CreateFeature
        isVisible={createModal}
        setVisible={setCreateModal}
        vehicle={vehicle}
      />
    </div>
  );
}

function Feature({ id, title, value, onUpdate, onDelete, key }: any) {
  return (
    <button
      className={` relative col-span-2 border flex flex-col justify-center items-center m-1 p-2 ${
        value ? "bg-green-50" : ""
      }`}
      key={key}
    >
      <button
        className="absolute top-0 right-0 z-50 bg-gray-100 w-6 h-6 flex items-center justify-center border-b border-l border-gray-200 hover:bg-red-200 hover:color-red"
        onClick={() => onDelete({id})}
      >
        <RiDeleteBinLine color="gray" />
      </button>
      <button
        className="flex flex-col items-center"
        onClick={() => onUpdate({ id, value })}
      >
        <div className="mb-1">{title}</div>
        {value ? (
          <div className="h-8 w-8 border flex justify-center items-center bg-green-50">
            <BsCheck2 size={20} />
          </div>
        ) : (
          <div className="h-8 w-8 border"></div>
        )}
      </button>
    </button>
  );
}
