import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createReview = async ({ data }: any) => {
  const response = await postData({ data, uri: "reviews" });
  return response;
};

const fetchReviews = async () => {
  const response = await fetchData({ uri: "reviews" });
  return response;
};

const fetchReview = async ({ id }: any) => {
  const response = await fetchData({ uri: `reviews/${id}` });
  return response;
};

const updateReview = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `reviews/${id}` });
  return response;
};

const deleteReview = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `reviews/${id}` });
  return response;
};

export { createReview, updateReview, fetchReviews, fetchReview, deleteReview };
