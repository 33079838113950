import React, { useState } from "react";

export default function SignIn() {
  const [mobPass, setMobPass] = useState<boolean>(false);
  const [isAuth, setAuth] = useState<boolean>(false);
  const [mobile, setMobile] = useState<any>("");
  const [code, setCode] = useState<any>("");
  const [user, setUser] = useState<any>({})
  const [mobErr, setMobErr] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSignIn = async () => {

  };

  const handleVerify = async () => {

  };

  return (
    <div className="bg-gray-300 flex-1 h-screen">
      <div className="bg-doshcars p-4 flex justify-between items-center border-b">
        <div className="">
          <span className="font-bold">DOSH</span>
          <span className="font-thin">CARS</span>
        </div>
        <div className="text-xs">USER PORTAL</div>
      </div>

      {isAuth ? (
        <div className="p-4">
          <div className="bg-white">
            <div className="p-4">USER INFORMATION</div>
            <div className="">{user?.name}</div>
          </div>

        </div>
      ) : (
        <div className="">
          {mobPass ? (
            <div className="flex justify-center">
              <div className=" bg-white mt-16 mx-4 w-full  md:w-1/4 lg:w-2/5 p-4">
                <div className="text-center border-b mb-1">OTP</div>
                <div className={`p-2 border ${mobErr ? "border-red-500" : ""}`}>
                  <input
                    type="text"
                    id={"Mobile"}
                    className="focus:outline-none"
                    onChange={(e) => setCode(e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Enter OTP"
                  />
                </div>
                <button
                  onClick={() => handleVerify()}
                  className="bg-doshcars py-1 px-4 my-2"
                >
                  {isLoading ? "Submit ...." : "Submit"}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div className=" bg-white mt-16 mx-4 w-full md:w-3/4 lg:w-2/5 p-4">
                <div className="text-center border-b mb-1">USER LOGIN</div>
                <div className={`p-2 border ${mobErr ? "border-red-500" : ""}`}>
                  <input
                    type="text"
                    id={"Code"}
                    className="focus:outline-none"
                    onChange={(e) => setMobile(e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Mobile Number ie. +255XXXXXXXXX"
                  />
                </div>
                <div className="">OTP Will be sent</div>
                <button
                  onClick={() => handleSignIn()}
                  className="bg-doshcars py-1 px-4 my-2"
                >
                  {isLoading ? "Next ...." : "Next"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
