import { Button, Form, Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProductOnsale } from "../../../../api/products";

export default function UpdateOnsale({
  isVisible,
  setVisible,
  is_on_sale,
  productId,
}: any) {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateProductOnsale,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const data = { is_on_sale: !is_on_sale };
    mutate({ product_id: productId, data });
  };

  const action = is_on_sale ? "Remove From Sale" : "Add To Sale";

  return (
    <Modal
      title={`${action}`}
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateProductOnsale"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          {action}
        </Button>,
      ]}
    >
      <Form
        id="updateProductOnsale"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          {is_on_sale ? (
            <div className="">
              Are you Sure you want to{" "}
              <span className="text-red-600 text-bold">{action}</span> This
              Product
            </div>
          ) : (
            <div className="">
              Are you Sure you want to{" "}
              <span className="text-green-600 text-bold">{action}</span> This
              Product
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
}
