import { Button, Form, Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateVehicleDeal } from "../../../../api/vehicles";

export default function UpdateDeal({
  isVisible,
  setVisible,
  is_on_sale,
  vehicleId,
}: any) {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateVehicleDeal,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const data = { is_on_sale: !is_on_sale };
    mutate({ id: vehicleId, data });
  };

  const action = is_on_sale ? "Remove on sale" : "Add on sale";

  return (
    <Modal
      title={`${action} Vehicle`}
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateVehicleDeal"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          {action}
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleDeal"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          {is_on_sale ? (
            <div className="">
              Are you Sure you want to{" "}
              <span className="text-red-600 text-bold">
                Remove it from Deals
              </span>{" "}
              This Vehicle
            </div>
          ) : (
            <div className="">
              Are you Sure you want to{" "}
              <span className="text-green-600 text-bold">Add it to deals</span>{" "}
              This Vehicle
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
}
