import { useQuery } from "@tanstack/react-query";
import { fetchBranches } from "../../../api/branches";
import Something from "../../../components/common/Something";
import { Link } from "react-router-dom";
import placeholder from "../../../assets/images/placeholder.png";
import CreateBranch from "./components/CreateBranch";
import ManageWrapper from "..";
import { FiMoreVertical } from "react-icons/fi";
import { RiDeleteBin4Line, RiEditBoxLine } from "react-icons/ri";
import { Popover } from "antd";
import { useState } from "react";
import UpdateBranch from "./components/UpdateBranch";
import DeleteBranch from "./components/DeleteBranch";
import UploadImage from "./components/UploadImage";
import { HiOutlinePhoto } from "react-icons/hi2";

export default function AllBranches() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { data, refetch, isFetching, isError } = useQuery({
    queryKey: ["branches"],
    queryFn: fetchBranches,
  });

  return (
    <ManageWrapper
      title={"Branches"}
      subtitle={"List of all branches"}
      actions={<CreateBranch />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something refetch={refetch} isFetching={isFetching} />
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-2">
            {data?.map((item: any) => (
              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3">
                <div className="flex flex-col items-center justify-center">
                  <div className="w-full flex justify-end items-center">
                    <div className="-mb-10 z-50">
                      <Popover
                        content={
                          <>
                            <div className="border-t"></div>
                            <button
                              onClick={() => {
                                setUploadModal(true);
                                setSelected(item);
                              }}
                              className="flex items-center hover:bg-gray-100 p-2 cursor-pointe w-full"
                            >
                              <div className="">
                                <HiOutlinePhoto size={15} />
                              </div>
                              <div className="ml-2">Upload</div>
                            </button>
                            <button
                              onClick={() => {
                                setSelected(item);
                                setUpdateModal(true);
                              }}
                              className="flex items-center hover:bg-gray-100 p-2 cursor-pointe w-full"
                            >
                              <div className="">
                                <RiEditBoxLine size={15} />
                              </div>
                              <div className="ml-2">Edit</div>
                            </button>
                            <button
                              onClick={() => {
                                setSelected(item);
                                setDeleteModal(true);
                              }}
                              className="flex items-center hover:bg-gray-100 p-2 cursor-pointer w-full"
                            >
                              <div className="">
                                <RiDeleteBin4Line />
                              </div>
                              <div className="ml-2">Delete</div>
                            </button>
                          </>
                        }
                        title={`${item?.name}`}
                        placement="bottom"
                      >
                        <FiMoreVertical />
                      </Popover>
                    </div>
                  </div>
                  <Link
                    className="flex flex-col justify-center items-center"
                    to={`/manage/branches/${item?.id}`}
                  >
                    <div className="flex justify-center items-center border  bg-gray-100">
                      <img
                        src={item?.image || placeholder}
                        width={"100%"}
                        className="aspect-[4/3] object-fill"
                      />
                    </div>
                    <div className="border flex flex-col justify-center items-center w-full pb-4">
                      <div className="text-blue-800 font-bold mt-4">
                        {item?.name} Branch
                      </div>
                      <div className="text-xs text-gray-500">
                        {item?.vehicles?.length} Vehicles
                      </div>
                      <div className="">
                        {item?.is_active ? (
                          <div className=" py-1 px-4  text-green-600 rounded-md text-xs">
                            OPEN
                          </div>
                        ) : (
                          <div className=" py-1 px-4  text-red-600 rounded-md text-xs">
                            CLOSED
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}

        <UpdateBranch
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />

        <UploadImage
          isVisible={uploadModal}
          setVisible={setUploadModal}
          selected={selected}
          setSelected={setSelected}
        />

        <DeleteBranch
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
