import { useState } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import UpdatePublish from "./UpdatePublish";

export default function PublishWidget({ is_published, vehicleId }: any) {
  const [publishModal, setPublishModal] = useState<boolean>(false);
  return (
    <div className="mb-4">
      <div className="flex justify-between items-center border-b border-t bg-gray-100 px-2 py-3">
        <div className=""><span className="font-bold">DOSH</span><span className="font-thin">CARS</span></div>
        <button onClick={() => setPublishModal(true)} className="">
          <FiEdit />
        </button>
      </div>
      <div className="flex justify-between p-2 text-xs">
        <div className="">Published to the apps?</div>
        <div className="">
          {is_published ? (
            <div className="flex items-center">
              <BsCheck2Square color="#16a34a" />
              <span className="ml-1 text-green-600">YES</span>
            </div>
          ) : (
            <div className="flex items-center">
              <AiOutlineCloseSquare color="#dc2626" />
              <span className="ml-1 text-red-600">NO</span>
            </div>
          )}
        </div>
      </div>
      <UpdatePublish
        isVisible={publishModal}
        setVisible={setPublishModal}
        is_published={is_published}
        vehicleId={vehicleId}
      />
    </div>
  );
}
