import { Button, Col, Form, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { BsCheck2Square } from "react-icons/bs";
import { uploadVehicleImage } from "../../../../api/vehicles";
import { positions } from "../../../../constants";

const UploadImage = ({ isVisible, setVisible, selected, vehicleId }: any) => {
  const [selectedFile, setSelectedFile] = useState<any>();
  const [isPicked, setPicked] = useState<boolean>(false);
  const changeHandler = (e: any) => {
    e.preventDefault();
    setSelectedFile(e.target.files[0]);
    setPicked(true);
  };

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: uploadVehicleImage,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      clearForm();
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const formData = new FormData();
        formData.append("file", selectedFile);
        mutate({
          id: vehicleId,
          file: formData,
          position: selected?.position,
          category: selected?.category,
          is_main: selected?.position === "e1" ? true : false,
        });
  };

  const clearForm = () => {
    setSelectedFile(null);
    setPicked(false);
  };

  return (
    <Modal
      title={`Upload Image  : ${positions[selected?.position] || ""}`}
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="uploadVehicleImage"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="uploadVehicleImage"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        {isPicked ? (
          <div className="">
            <div className="">
              <BsCheck2Square />
            </div>
            <div className="">Image Selected</div>
          </div>
        ) : (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <input
                type="file"
                id="file"
                name="file"
                onChange={changeHandler}
                placeholder="Upload Image"
              />
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default UploadImage;
