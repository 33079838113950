import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createSubcat = async ({ data }: any) => {
  const response = await postData({ data, uri: "subcats" });
  return response;
};

const fetchSubcats = async () => {
  const response = await fetchData({ uri: "subcats" });
  return response;
};

const updateSubcat = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `subcats/${id}` });
  return response;
};

const uploadImage = async ({ id, file, name }: any) => {
  const response = await uploadFile({
    file,
    uri: `subcats/${id}/image?name=${name}`,
  });
  return response;
};

const deleteSubcat = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `subcats/${id}` });
  return response;
};

export { createSubcat, updateSubcat, fetchSubcats, uploadImage, deleteSubcat };
