import { useQuery } from "@tanstack/react-query";
import { fetchOptions } from "../../../api/options";
import Something from "../../../components/common/Something";
import placeholder from "../../../assets/images/placeholder.png";
import ManageWrapper from "..";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { useState } from "react";
import UpdateBranch from "./components/UpdateBranch";
import DeleteBranch from "./components/DeleteBranch";
import UploadImage from "./components/UploadImage";
import { HiOutlinePhoto } from "react-icons/hi2";
import CreateOption from "./components/CreateOption";

export default function AllOptions() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { data: options, refetch, isFetching, isError } = useQuery({
    queryKey: ["options"],
    queryFn: fetchOptions,
  });

  return (
    <ManageWrapper
      title={"Options"}
      subtitle={"List of all options"}
      actions={<CreateOption />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something refetch={refetch} isFetching={isFetching} />
          </div>
        ) : (
          <div className="w-full">
          <div className="">
            <div className="flex justify-between border-b items-center bg-gray-100 p-2">
              <div className="flex justify-center">
                <div className="w-12">Icon</div>
                <div className="w-44">Name</div>
                <div className="w-16">Active</div>
              </div>
              <div className="">Actions</div>
            </div>
            {options?.map((item: any, index: number) => (
              <div className="flex justify-between border-b items-center p-2" key={index}>
                <div className="flex justify-center">
                  <button onClick={() => setUploadModal(true)} className="w-12"><img src={item?.flag || placeholder} /></button>
                  <div className="w-44">{item?.name}</div>
                  <div className="w-16">{item?.is_active ? "YES": "NO"}</div>
                </div>
                <div className="flex">
                  <button
                    onClick={() => {
                      setSelected(item);
                      setUpdateModal(true);
                    }}
                    className="border bg-yellow-100 rounded-md p-2 mr-1"
                  >
                    <FiEdit />
                  </button>
                  <button
                    onClick={() => {
                      setSelected(item);
                      setDeleteModal(true);
                    }}
                    className="border bg-red-100 rounded-md p-2 mr-1"
                  >
                    <RiDeleteBin4Line />
                  </button>
                  <button
                    onClick={() => {
                      setSelected(item);
                      setUploadModal(true);
                    }}
                    className="border bg-blue-100 rounded-md p-2"
                  >
                    <HiOutlinePhoto />
                  </button>
                </div>
              </div>
            ))}
          </div>
          </div>
        )}

        <UpdateBranch
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />

        <UploadImage
          isVisible={uploadModal}
          setVisible={setUploadModal}
          selected={selected}
          setSelected={setSelected}
        />

        <DeleteBranch
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
