import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addProductHowto } from "../../../../api/products";
import TextArea from "antd/es/input/TextArea";

export default function CreateHowto({ isVisible, setVisible, productId }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: addProductHowto,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Added Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { title, description } = await form.validateFields();
    const data = {
      title,
      description,
      product_id: Number(productId),
    };
    mutate({ id: productId, data });
  };

  return (
    <Modal
      title="Add Howto"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="addProductHowto"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="addProductHowto"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
