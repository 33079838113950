import { Button,  Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import extractOptions from "../../../../utils/extractOptions";
import { addProductFitment } from "../../../../api/products";
import { fetchAllGenerations } from "../../../../api/generations";
import extractFitments from "../../../../utils/extractFitments";

const { Option } = Select;

export default function CreateFitment({
  isVisible,
  setVisible,
  product,
  productId,
}: any) {
  const queryClient = useQueryClient();
  const [option, setOption] = useState<any>({});

  const { data: generations } = useQuery({
    queryKey: ["generations"],
    queryFn: fetchAllGenerations,
  });

  const options = extractFitments(generations, product?.fitments);

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: addProductFitment,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Added Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["products", productId] });
      setVisible(false);
    },
  });

  const handleSelected = (id: any) => {
    const filtered = options?.filter((option: any) => option.id === id);
    setOption(filtered[0]);
  };

  const optOptions = options?.map((option: any, index: number) => (
    <Option value={option?.id} key={index}>
      {option?.description}
    </Option>
  ));

  const onSubmit = async () => {
    const data = {
      name: option?.name,
      code: option?.name,
      description: option?.description,
      generation_id: option?.id,
      product_id: Number(productId),
    };
    mutate({ id: productId, data });
  };

  return (
    <Modal
      title="Add Fitment"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="addProductFitment"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="addProductFitment"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="generation_id"
              label="Generation"
              rules={[
                {
                  required: true,
                  message: "Please enter generation",
                },
              ]}
            >
              <Select placeholder="Generation" onSelect={(e) => handleSelected(e)}>
                {optOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
