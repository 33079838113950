import { Button, Checkbox, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBrand } from "../../../../api/brands";
import { useEffect, useState } from "react";

const UpdateBrand = ({ isVisible, setVisible, selected }: any) => {
  const { id } = selected;
  const queryClient = useQueryClient();
  const [isActive, setActive] = useState<boolean>(selected?.is_active);

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateBrand,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["brands"] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { name, count } = await form.validateFields();
    const data = { name, is_active: isActive, count: Number(count) };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
    setActive(selected?.is_active);
  }, [selected]);

  return (
    <>
      <Modal
        title="Update Brand"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="updateBrand"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="updateBrand"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Brand Name"
                initialValue={selected?.name}
                rules={[
                  {
                    required: true,
                    message: "Please enter brand name",
                  },
                ]}
              >
                <Input placeholder="Brand Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="count"
                label="Vehicles Count"
                initialValue={selected?.name}
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle count",
                  },
                ]}
              >
                <Input placeholder="Vehicles Count" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Checkbox
                defaultChecked={selected?.is_active}
                onChange={() => setActive(!isActive)}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateBrand;
