import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { updateVehicleSpecs } from "../../../../api/vehicles";
const { Option } = Select;
const UpdateDetailsTwo = ({
  isVisible,
  setVisible,
  vehicle,
  vehicleId,
}: any) => {
  const { id } = vehicle;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateVehicleSpecs,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...vehicle });
  }, [vehicle]);

  return (
    <>
      <Modal
        title="Update Details"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="updateVehicleDetailsTwo"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="updateVehicleDetailsTwo"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="mileage"
                label="Mileage ( Km )"
                initialValue={vehicle?.mileage}
                rules={[
                  {
                    required: false,
                    message: "Please enter mileage",
                  },
                ]}
              >
                <Input placeholder="Mileage" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="tank_size"
                label="Tank Size ( Litres )"
                initialValue={vehicle?.tank_size}
                rules={[
                  {
                    required: false,
                    message: "Please enter tank size",
                  },
                ]}
              >
                <Input type="number" placeholder="Tank Size" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="engine_type"
                label="Engine Type"
                initialValue={vehicle?.engine_type}
                rules={[
                  {
                    required: false,
                    message: "Please select enter engine type",
                  },
                ]}
              >
                <Select placeholder="Engine Type">
                  <Option value="Internal petrol combustion engine (ESS)">
                    Internal petrol combustion engine (ESS)
                  </Option>
                  <Option value="Diesel internal combustion engine (DSL)">
                    Diesel internal combustion engine (DSL)
                  </Option>
                  <Option value="Mild hybrid electric vehicle (MHEV)">
                    Mild hybrid electric vehicle (MHEV)
                  </Option>
                  <Option value="Hybrid electric vehicle (HEV)">
                    Hybrid electric vehicle (HEV)
                  </Option>
                  <Option value="Plug-in hybrid electric vehicle (PHEV)">
                    Plug-in hybrid electric vehicle (PHEV)
                  </Option>
                  <Option value="Compressed natural gas engine (CNG)">
                    Compressed natural gas engine (CNG)
                  </Option>
                  <Option value="Liquefied petroleum gas engine (LPG)">
                    Liquefied petroleum gas engine (LPG)
                  </Option>
                  <Option value="100% electric motor (battery electric vehicle – BEV)">
                    100% electric motor (battery electric vehicle – BEV)
                  </Option>
                  <Option value="Fuel cell electric vehicle (FCEV)">
                    Fuel cell electric vehicle (FCEV)
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="engine_size"
                label="Engine Size ( Cc )"
                initialValue={vehicle?.engine_size}
                rules={[
                  {
                    required: false,
                    message: "Please enter engine size",
                  },
                ]}
              >
                <Input placeholder="Engine Size" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="max_load"
                label="Max Load ( Kg )"
                initialValue={vehicle?.doors}
                rules={[
                  {
                    required: false,
                    message: "Please enter maximum load",
                  },
                ]}
              >
                <Input type="number" placeholder="Max Load" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="seats"
                label="Seats"
                initialValue={vehicle?.seats}
                rules={[
                  {
                    required: false,
                    message: "Please enter number of seats",
                  },
                ]}
              >
                <Input type="number" placeholder="Seats" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="doors"
                label="Doors"
                initialValue={vehicle?.doors}
                rules={[
                  {
                    required: false,
                    message: "Please enter number of doors",
                  },
                ]}
              >
                <Input type="number" placeholder="Doors" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="dimension"
                label="Dimension"
                initialValue={vehicle?.dimension}
                rules={[
                  {
                    required: false,
                    message: "Please enter dimension",
                  },
                ]}
              >
                <Input placeholder="Dimension" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="reg_number"
                label="Number Plate"
                initialValue={vehicle?.reg_number}
                rules={[
                  {
                    required: false,
                    message: "Please enter number plate",
                  },
                ]}
              >
                <Input placeholder="Number Plate" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateDetailsTwo;
