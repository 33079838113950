import { TfiFaceSad } from "react-icons/tfi";
import { SlReload } from "react-icons/sl";

export default function Something({ title, isFetching }: any) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="">
        <TfiFaceSad size={35} />
      </div>
      <div className="">Sorry, Something went wrong</div>
      <div className="border rounded-md py-1 px-2 flex justify-center items-center">
        <SlReload />
        Retry
      </div>
    </div>
  );
}
