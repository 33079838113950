import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { updateOrderState } from "../../../api/orders";

const { Option } = Select;
const { TextArea } = Input;

export default function CreateState({
  isVisible,
  setVisible,
  order,
  order_id,
  state,
}: any) {
  const queryClient = useQueryClient();
  const [isLast, setLast] = useState<boolean>(state?.is_last || false);

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateOrderState,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["orders", order_id] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { title, status, description, due_date } =
      await form.validateFields();
    const data = {
      title,
      status,
      description,
      due_date,
      is_last: isLast,
      order_id: order?.id,
    };

    mutate({ state_id: state?.id, data });
  };

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({
      title: state?.title,
      status: state?.status,
      description: state?.description,
      is_last: state?.is_last
    })  

  },[state])

  return (
    <Modal
      title="Update State"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateOrderState"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateOrderState"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              label="Title"
              initialValue={state?.title}
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="due_date"
              label="Due Date"
              rules={[
                {
                  required: false,
                  message: "Please enter due date",
                },
              ]}
            >
              <DatePicker placeholder="Due date" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="status"
              label="Status"
              initialValue={state?.status}
              rules={[
                {
                  required: false,
                  message: "Please enter status",
                },
              ]}
            >
              <Select placeholder="Status" defaultValue={"Pending"}>
                <Option value="Pending">Pending</Option>
                <Option value="Inprogress">Inprogress</Option>
                <Option value="Completed">Completed</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              initialValue={state?.description}
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Checkbox defaultChecked={isLast} onChange={() => setLast(!isLast)}>
              Is Last
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
