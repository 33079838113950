import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { updateCustomer } from "../../../../api/customers";
import { fetchCountries } from "../../../../api/countries";
const { Option } = Select;

const UpdateCustomer = ({ isVisible, setVisible, selected }: any) => {
  const { id, name, email, mobile, country_id } = selected;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateCustomer,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["customers"] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    mutate({ id, data });
  };

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: fetchCountries,
  });

  const countryOptions = countries?.map((country: any, index: number) => (
    <Option value={country?.id}>
      {country?.flag} {country?.name}
    </Option>
  ));

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <>
      <Modal
        title="Update Customer"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="updateCustomer"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="updateCustomer"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Name"
                initialValue={name}
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="mobile"
                label="Mobile"
                initialValue={mobile}
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile",
                  },
                ]}
              >
                <Input placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="email"
                label="Email"
                initialValue={email}
                rules={[
                  {
                    required: false,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="country_id"
                initialValue={country_id}
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please select country",
                  },
                ]}
              >
                <Select placeholder="Country">{countryOptions}</Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCustomer;
