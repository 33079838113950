import { useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { fetchVehicleReservations } from "../../../../api/reservations";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../../components/common/Something";
import Moment from "react-moment";
import ReserveVehicle from "./ReserveVehicle";
import CancelReservation from "./CancelReservation";
import { statusColors } from "../../../../utils/statusColors";

export default function Reservations({ vehicle, vehicleId }: any) {
  const [reserveModal, setReserveModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const { data: reservations, isError } = useQuery({
    queryKey: ["reservations", vehicleId],
    queryFn: () => fetchVehicleReservations({ vehicle_id: vehicleId }),
  });

  const checkActive = () => {
    let canReserve = true;
    let active = reservations?.filter((item: any) => item?.status === "Active");
    if (active?.length > 0) {
      canReserve = false;
    }
    return canReserve;
  };

  const canReserve = checkActive();

  return (
    <div className="">
      <div className="flex justify-between items-center border-b border-t bg-gray-100 px-2 py-3">
        <div className="">Vehicle Reservations</div>
        {canReserve && (
          <button onClick={() => setReserveModal(true)} className="">
            <AiOutlinePlusSquare size={18} />
          </button>
        )}
      </div>

      {isError ? (
        <Something />
      ) : (
        <div className="">
          {reservations?.map((reservation: any, index: number) => (
            <div className="flex flex-col mx-2 border-b py-2">
              <div className="flex">
                <div className="w-6">{index + 1}.</div>
                <div className="flex flex-col w-full">
                  <div className="flex justify-between">
                    <div className="font-bold">{reservation?.user?.name}</div>
                    <div className="flex items-center">
                      <span style={{color: `${statusColors[reservation?.status]}`}} className={`mr-2`}>{reservation.status}</span>{" "}
                      {reservation?.status === "Active" ? (
                        <button
                          className=""
                          onClick={() => {
                            setSelected(reservation);
                            setCancelModal(true);
                          }}
                        >
                          <MdOutlineCancelPresentation />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    Starts:
                    <Moment format="Do MMM YYYY">
                      {reservation?.start_date}
                    </Moment>
                  </div>
                  <div className="flex justify-between">
                    Ends:
                    <Moment format="Do MMM YYYY">
                      {reservation?.end_date}
                    </Moment>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ReserveVehicle
        isVisible={reserveModal}
        setVisible={setReserveModal}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
      />

      <CancelReservation
        isVisible={cancelModal}
        setVisible={setCancelModal}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
        reservation={selected}
      />
    </div>
  );
}
