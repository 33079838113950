const imagePositions: any = ({ product, placeholder }: any) => {
    let A1 = "";
    let A2 = "";
    let A3 = "";
    let A4 = "";
    let A5 = "";
    let A6 = "";
    let A7 = "";
    let A8 = "";
    let A9 = "";
    let A10 = "";
    let A11 = "";
    let A12 = "";
  
    if (product?.images) {
      product?.images?.forEach((image: any) => {
        switch (image?.position) {
          case "a1":
            A1 = image?.url;
            break;
          case "a2":
            A2 = image?.url;
            break;
          case "a3":
            A3 = image?.url;
            break;
          case "a4":
            A4 = image?.url;
            break;
          case "a5":
            A5 = image?.url;
            break;
          case "a6":
            A6 = image?.url;
            break;
          case "a7":
            A7 = image?.url;
            break;
          case "a8":
            A8 = image?.url;
            break;
          case "a9":
            A9 = image?.url;
            break;
          case "a10":
            A10 = image?.url;
            break;
          case "a11":
            A11 = image?.url;
            break;
          case "a12":
            A12 = image?.url;
            break;
        }
      });
    }
  
    return {
      a1: A1 || placeholder,
      a2: A2 || placeholder,
      a3: A3 || placeholder,
      a4: A4 || placeholder,
      a5: A5 || placeholder,
      a6: A6 || placeholder,
      a7: A7 || placeholder,
      a8: A8 || placeholder,
      a9: A9 || placeholder,
      a10: A10 || placeholder,
      a11: A11 || placeholder,
      a12: A12 || placeholder,
    };
  };
  
  export default imagePositions;
  