const positionImages: any = ({ vehicle, placeholder }: any) => {
  let F1 = "";
  let F2 = "";
  let S1 = "";
  let S2 = "";
  let B1 = "";
  let B2 = "";
  let I1 = "";
  let I2 = "";
  let I3 = "";
  let I4 = "";

  if (vehicle?.vimages) {
    vehicle?.vimages?.forEach((image: any) => {
      switch (image?.position) {
        case "f1":
          F1 = image?.url;
          break;
        case "f2":
          F2 = image?.url;
          break;
        case "s1":
          S1 = image?.url;
          break;
        case "s2":
          S2 = image?.url;
          break;
        case "b1":
          B1 = image?.url;
          break;
        case "b2":
          B2 = image?.url;
          break;
        case "i1":
          I1 = image?.url;
          break;
        case "i2":
          I2 = image?.url;
          break;
        case "i3":
          I3 = image?.url;
          break;
        case "i4":
          I4 = image?.url;
          break;
      }
    });
  }

  return {
    f1: F1 || placeholder,
    f2: F2 || placeholder,
    s1: S1 || placeholder,
    s2: S2 || placeholder,
    b1: B1 || placeholder,
    b2: B2 || placeholder,
    i1: I1 || placeholder,
    i2: I2 || placeholder,
    i3: I3 || placeholder,
    i4: I4 || placeholder,
  };
};

export default positionImages;
