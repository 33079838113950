import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { saleVehicle } from "../../../../api/vehicles";
import { fetchUsers } from "../../../../api/users";
const { Option } = Select;

export default function SaleVehicle({
  isVisible,
  setVisible,
  hideModal,
  vehicleId,
}: any) {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: saleVehicle,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Sold Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      queryClient.invalidateQueries({ queryKey: ["reservations", vehicleId] });
      setVisible(false);
      hideModal();
    },
  });

  const onSubmit = async () => {
    const { user_id } = await form.validateFields();
    mutate({ id: vehicleId, data: { user_id } });
  };

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
  });

  const userOptions = users?.map((user: any, index: number) => (
    <Option value={user?.id}>{user?.name}</Option>
  ));

  return (
    <>
      <Modal
        title="Sale Vehicle"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="saleVehicle"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="saleVehicle"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="user_id"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select customer",
                  },
                ]}
              >
                <Select
                  placeholder="Customer"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {userOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
