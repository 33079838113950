import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { updateVehicleMake } from "../../../../api/vehicles";
import { fetchMakes } from "../../../../api/makes";

const { Option } = Select;

export default function UpdateMake({
  isVisible,
  setVisible,
  vehicleId,
}: any) {
  const { data: makes } = useQuery({
    queryKey: ["makes"],
    queryFn: fetchMakes,
  });

  const [models, setModels] = useState<any>([]);

  const [make, setMake] = useState<string>("");
  const [model, setModel] = useState<string>("");

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const handleModels = (id: any) => {
    const make = makes?.filter((make: any) => make.id === id);
    setMake(make[0]?.name);
    setModels(make[0]?.models);
  };

  const handleModel = (id: any) => {
    const model = models.filter((model: any) => model.id === id);
    setModel(model[0]?.name);
  };


  const { mutate, isLoading } = useMutation({
    mutationFn: updateVehicleMake,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const makeOptions = makes?.map((make: any, index: number) => (
    <Option value={make?.id} key={index}>
      {make?.name}
    </Option>
  ));

  const modelOptions = models?.map((model: any, index: number) => (
    <Option value={model?.id} key={index}>
      {model?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const { make_id, model_id } = await form.validateFields();
    const data = {
      name: `${make} ${model}`,
      make_id,
      model_id,
    };
    mutate({ id: vehicleId, data });
  };

  return (
    <Modal
      title="Update Model"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateVehicleMake"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleMake"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="make_id"
              label="Make"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle make",
                },
              ]}
            >
              <Select placeholder="Make" onSelect={(e: any) => handleModels(e)}>
                {makeOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="model_id"
              label="Model"
              rules={[
                {
                  required: true,
                  message: "Please enter model",
                },
              ]}
            >
              <Select placeholder="Model"  onSelect={(e: any) => handleModel(e)}>
                {modelOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
