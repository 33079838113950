import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createModel = async ({ data }: any) => {
  const response = await postData({ data, uri: "models" });
  return response;
};

const fetchModels = async () => {
  const response = await fetchData({ uri: "models" });
  return response;
};

const updateModel = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `models/${id}` });
  return response;
};

const deleteModel = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `models/${id}` });
  return response;
};

export { createModel, fetchModels, updateModel, deleteModel };
