import { useQuery } from "@tanstack/react-query";
import { Button, Drawer, Space } from "antd";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { fetchCountry } from "../../../../api/countries";
import Something from "../../../../components/common/Something";
import CreateRegion from "./CreateRegion";
import { useState } from "react";
import { RiDeleteBin4Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import UpdateRegion from "./UpdateRegion";
import DeleteRegion from "./DeleteRegion";

export default function DetailsPanel({ isVisible, setVisible, selected }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [region, setRegion] = useState<any>({});
  const { id } = selected;
  const { data, isError } = useQuery({
    queryKey: ["countries", id],
    queryFn: () => fetchCountry({ id }),
  });
  return (
    <Drawer
      title={`${selected?.name}`}
      placement={"right"}
      width={500}
      closable={false}
      onClose={() => setVisible(false)}
      open={isVisible}
      extra={
        <Space>
          <Button danger onClick={() => setVisible(false)}>
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      {isError ? (
        <div className="py-2">
          <Something />
        </div>
      ) : (
        <div className="-mt-5">
          <div className="flex justify-between items-center bg-gray-100 border-b-2 py-2">
            <div className="">Regions</div>
            <Button onClick={() => setCreateModal(true)}>
              <AiOutlinePlus />
            </Button>
          </div>
          <div className="">
            {data?.regions?.map((item: any, index: number) => (
              <div className="flex justify-between items-center py-2 border-b">
                <div className="flex">
                  <div className="">{index + 1} .</div>
                  <div className="">{item?.name}</div>
                </div>
                <div className="">
                  <div className="flex">
                    <button
                      onClick={() => {
                        setRegion(item);
                        setUpdateModal(true);
                      }}
                      className="border bg-yellow-100 rounded-md p-2 mr-1"
                    >
                      <FiEdit />
                    </button>
                    <button
                      onClick={() => {
                        setRegion(item);
                        setDeleteModal(true);
                      }}
                      className="border bg-red-100 rounded-md p-2 mr-1"
                    >
                      <RiDeleteBin4Line />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <CreateRegion
        isVisible={createModal}
        setVisible={setCreateModal}
        selected={selected}
      />
      <UpdateRegion
        isVisible={updateModal}
        setVisible={setUpdateModal}
        selected={region}
      />
      <DeleteRegion
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        selected={region}
      />
    </Drawer>
  );
}
