import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createReservation = async ({ data }: any) => {
  const response = await postData({ data, uri: "reservations" });
  return response;
};

const fetchAllReservations = async () => {
  const response = await fetchData({ uri: "reservations" });
  return response;
};

const fetchVehicleReservations = async ({vehicle_id}: any) => {
  const response = await fetchData({ uri: `reservations/vehicles/${vehicle_id}` });
  return response;
};

const fetchUserReservations = async ({user_id}: any) => {
  const response = await fetchData({ uri: `reservations/users/${user_id}` });
  return response;
};

const updateReservation = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `reservations/${id}` });
  return response;
};

const cancelReservation = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `reservations/${id}/cancel` });
  return response;
};

export {
  createReservation,
  updateReservation,
  fetchAllReservations,
  fetchVehicleReservations,
  fetchUserReservations,
  cancelReservation,
};
