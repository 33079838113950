import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import { useEffect } from "react";
import { fetchUsers } from "../../../../api/users";
import { createReservation } from "../../../../api/reservations";
import Moment from "react-moment";
const { Option } = Select;

export default function ReserveVehicle({
  isVisible,
  setVisible,
  vehicle,
  vehicleId,
}: any) {
  const startDate = new Date();
  const endDate = new Date().setDate(startDate.getDate() + 7);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: createReservation,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Reserved Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["reservations", vehicleId] });
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { user_id } = await form.validateFields();
    const data = {
      vehicle_id: vehicle?.id,
      user_id: user_id || null,
      start_date: startDate,
      end_date: endDate,
    };
    await mutate({ data });
  };

  const userOptions = users?.map((user: any, index: number) => (
    <Option value={user?.id}>{user?.name}</Option>
  ));

  useEffect(() => {
    form.setFieldsValue({ ...vehicle });
  }, [vehicle]);

  return (
    <Modal
      title="Reserve Vehicle"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createVehicleReservation"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Reserve
        </Button>,
      ]}
    >
      <div className="">
        <div className="mb-4">
          <div className="flex justify-between mb-2">
            <div className="">Reservation Starts :</div>
            <div className="font-bold">
              <Moment format="Do MMM, YYYYY">{startDate}</Moment>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="">Reservation Ends :</div>
            <div className="font-bold">
              <Moment format="Do MMM, YYYYY">{endDate}</Moment>
            </div>
          </div>
        </div>
        <Form
          id="createVehicleReservation"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="user_id"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select customer",
                  },
                ]}
              >
                <Select
                  placeholder="Customer"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {userOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
