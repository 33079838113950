function extractFitments(originalArray: any, subsetArray: any): any[] {
  const subsetIds = new Set(subsetArray?.map((feature: any) => feature?.id));

  const uniqueObjects = originalArray?.filter(
    (option: any) => !subsetIds.has(option?.id)
  );

  return uniqueObjects;
}

export default extractFitments;
