export default function ImageWidget({
  editable,
  setSelected,
  setVisible,
  image,
}: any) {
  return (
    <div className="border bg-gray-100">
      <button
        onClick={() => {
          if (editable) {
            setSelected({ position: "s1" });
            setVisible(true);
          }
        }}
        className=""
      >
        <img src={image} width={150} />
      </button>
    </div>
  );
}
