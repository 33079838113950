import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createCountry = async ({ data }: any) => {
  const response = await postData({ data, uri: "countries" });
  return response;
};

const fetchCountries = async () => {
  const response = await fetchData({ uri: "countries" });
  return response;
};

const fetchCountry = async ({ id }: any) => {
  const response = await fetchData({ uri: `countries/${id}` });
  return response;
};

const updateCountry = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `countries/${id}` });
  return response;
};

const deleteCountry = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `countries/${id}` });
  return response;
};

export {
  createCountry,
  updateCountry,
  fetchCountries,
  fetchCountry,
  deleteCountry,
};
