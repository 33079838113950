import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/common/Something";
import { useParams } from "react-router";
import BackToolNav from "../../../components/toolnav/BackToolNav";
import { useState } from "react";
import { Button } from "antd";
import { FiEdit } from "react-icons/fi";
import ManageWrapper from "..";
import ProductHeader from "./components/ProductHeader";
import VehicleImages from "./components/VehicleImages";
import VehicleDetailsOne from "./components/VehicleDetailsOne";
import PublishWidget from "./components/PublishWidget";
import PriceWidget from "./components/PriceWidget";
import { fetchPost } from "../../../api/posts";

export default function OnePost() {
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const { id } = useParams();
  const { data: vehicle, isError } = useQuery({
    queryKey: ["posts", id],
    queryFn: () => fetchPost({ id }),
  });

  return (
    <ManageWrapper
      title={"Post"}
      subtitle={"Post details"}
      actions={
        <div className="flex items-center">
          <Button onClick={() => setUpdateModal(true)}>
            <FiEdit />
          </Button>
        </div>
      }
      toolnav={<BackToolNav />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 sm:col-span-9 border">
                <ProductHeader
                  product={vehicle}
                  editable={true}
                  productId={id}
                />
                <VehicleImages
                  vehicle={vehicle}
                  editable={true}
                  vehicleId={id}
                />
                <div className="grid grid-cols-12">
                  <div className="col-span-12 sm:col-span-12">
                    <VehicleDetailsOne
                      product={vehicle}
                      editable={true}
                      vehicleId={id}
                    />
                  </div>
                </div>

              </div>
              <div className="col-span-12 sm:col-span-3 border">
                <PublishWidget
                  is_published={vehicle?.is_published}
                  vehicleId={id}
                />
                <PriceWidget vehicle={vehicle} vehicleId={id} />
              </div>
            </div>
          </>
        )}
      </div>
    </ManageWrapper>
  );
}
