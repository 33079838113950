import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createUser = async ({ data }: any) => {
  const response = await postData({ data, uri: "users" });
  return response;
};

const fetchUsers = async () => {
  const response = await fetchData({ uri: "users" });
  return response;
};

const fetchUser = async ({ id }: any) => {
  const response = await fetchData({ uri: `users/${id}` });
  return response;
};

const updateUser = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `users/${id}` });
  return response;
};

const deleteUser = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `users/${id}` });
  return response;
};

export {
  createUser,
  updateUser,
  fetchUsers,
  fetchUser,
  deleteUser,
};
