import React from "react";

export default function NotFound() {
  return (
    <div className="">
      <div className="flex flex-col justify-center items-center mt-16">
        <div className="text-6xl text-gray-400">404</div>
        <div className="text-gray-500">Page Not Found</div>
      </div>
    </div>
  );
}
