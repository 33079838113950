import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createVehicle } from "../../../../api/vehicles";
import { fetchMakes } from "../../../../api/makes";
import { fetchSuppliers } from "../../../../api/suppliers";
import { fetchSellers } from "../../../../api/sellers";

const { Option } = Select;

const CreateVehicle: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [models, setModels] = useState<any>([]);

  const [make, setMake] = useState<string>("");
  const [model, setModel] = useState<string>("");

  const queryClient = useQueryClient();

  const { data: makes } = useQuery({
    queryKey: ["makes"],
    queryFn: fetchMakes,
  });


  const { data: suppliers } = useQuery({
    queryKey: ["suppliers"],
    queryFn: fetchSuppliers,
  });


  const { data: sellers } = useQuery({
    queryKey: ["sellers"],
    queryFn: fetchSellers,
  });

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createVehicle,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
      setOpen(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = {
      name: `${make} ${model}`,
      country_id: 1,
      region_id: 1,
      ...values,
      myear: Number(values?.myear),
      ryear: Number(values?.ryear),
      price: Number(values.price),
    };
    mutate({ data });
  };

  const handleModels = (id: any) => {
    const make = makes?.filter((make: any) => make.id === id);
    setMake(make[0]?.name);
    setModels(make[0]?.models);
  };

  const handleModel = (id: any) => {
    const model = models.filter((model: any) => model.id === id);
    setModel(model[0]?.name);
  };

  const makeOptions = makes?.map((make: any, index: number) => (
    <Option value={make?.id} key={index}>
      {make?.name}
    </Option>
  ));

  const modelOptions = models?.map((model: any, index: number) => (
    <Option value={model?.id} key={index}>
      {model?.name}
    </Option>
  ));


  const supplierOptions = suppliers?.map((supplier: any, index: number) => (
    <Option value={supplier?.id} key={index}>
      {supplier?.name}
    </Option>
  ));

  const sellerOptions = sellers?.map((seller: any, index: number) => (
    <Option value={seller?.id} key={index}>
      {seller?.name}
    </Option>
  ));
  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      <Modal
        title="Create Vehicle"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => form.resetFields()}>
            Clear
          </Button>,
          <Button
            key="submit"
            form="createVehicle"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createVehicle"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="chassis"
                label="Chassis Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter chassis number",
                  },
                ]}
              >
                <Input placeholder="Chassis Number" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  {
                    required: false,
                    message: "Please enter Price",
                  },
                ]}
              >
                <Input placeholder="Price" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="make_id"
                label="Make"
                rules={[
                  {
                    required: true,
                    message: "Please select vehicle make",
                  },
                ]}
              >
                <Select
                  placeholder="Make"
                  onSelect={(e: any) => handleModels(e)}
                >
                  {makeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="model_id"
                label="Model"
                rules={[
                  {
                    required: true,
                    message: "Please enter model",
                  },
                ]}
              >
                <Select
                  placeholder="Model"
                  onSelect={(e: any) => handleModel(e)}
                >
                  {modelOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="myear"
                label="Manufacturing"
                rules={[
                  {
                    required: true,
                    message: "Please enter manufacturing year",
                  },
                ]}
              >
                <Input type="number" placeholder="Year" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="ryear"
                label="Registration"
                rules={[
                  {
                    required: true,
                    message: "Please enter registration year",
                  },
                ]}
              >
                <Input type="number" placeholder="Year" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="mileage"
                label="Mileage"
                rules={[
                  {
                    required: true,
                    message: "Please enter mileage",
                  },
                ]}
              >
                <Input type="number" placeholder="Mileage" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="body_type"
                label="Body Type"
                rules={[
                  {
                    required: true,
                    message: "Please select body type",
                  },
                ]}
              >
                <Select placeholder="Body Type">
                  <Option value="Sedan">Sedan</Option>
                  <Option value="Coupe">Coupe</Option>
                  <Option value="Sports Car">Sports Car</Option>
                  <Option value="Station Wagon">Station Wagon</Option>
                  <Option value="Hatchback">Hatchback</Option>
                  <Option value="Convertible">Convertible</Option>
                  <Option value="SUV">SUV</Option>
                  <Option value="Minivan">Minivan</Option>
                  <Option value="Pick up Truck">Pick up Truck</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="fuel_type"
                label="Fuel Type"
                rules={[
                  {
                    required: true,
                    message: "Please select fuel type",
                  },
                ]}
              >
                <Select placeholder="Model">
                  <Option value="Petrol">Petrol</Option>
                  <Option value="Diesel">Diesel</Option>
                  <Option value="Electric">Electric</Option>
                  <Option value="Electric Hybrid">Electric Hybrid</Option>
                  <Option value="Hydrogen Fuel">Hydrogen Fuel</Option>
                  <Option value="Synthetic Fuel">Synthetic Fuel</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="transmission"
                label="Transmission"
                rules={[
                  {
                    required: true,
                    message: "Please select transmission",
                  },
                ]}
              >
                <Select placeholder="Transmission">
                  <Option value="Manual">Manual</Option>
                  <Option value="Automatic">Automatic</Option>
                  <Option value="Semi Automatic">Semi Automatic</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="steering"
                label="Steering"
                rules={[
                  {
                    required: true,
                    message: "Please select steering",
                  },
                ]}
              >
                <Select placeholder="Steering">
                  <Option value="Left Hand Drive">Left Hand Drive</Option>
                  <Option value="Right Hand Drive">Right Hand Drive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="supplier_id"
                label="Supplier"
                rules={[
                  {
                    required: true,
                    message: "Please select supplier",
                  },
                ]}
              >
                <Select showSearch placeholder="Supplier">
                {supplierOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="seller_id"
                label="Seller"
                rules={[
                  {
                    required: true,
                    message: "Please select steering",
                  },
                ]}
              >
                <Select showSearch placeholder="Seller">
                {sellerOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateVehicle;
