import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCategory } from "../../../../api/categories";

const CreateCategory: React.FC = () => {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createCategory,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      setOpen(false);
    },
  });

  const onSubmit = async () => {
    const { name, count, index } = await form.validateFields();
    const data = { name, count: Number(count), index: Number(index) };
    mutate({ data });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      <Modal
        title="Create Category"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="createCategory"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createCategory"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="count"
                label="Count"
                rules={[
                  {
                    required: true,
                    message: "Please enter count",
                  },
                ]}
              >
                <Input placeholder="Count" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="index"
                label="Index"
                rules={[
                  {
                    required: true,
                    message: "Please enter index",
                  },
                ]}
              >
                <Input placeholder="Index" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCategory;
