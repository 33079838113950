const statusColors: any = {
    Available: "#15803d",
    Reserved: "#1d4ed8",
    Negotiation: "#eab308",
    Sold: "#dc2626",
    Active: "#15803d",
    Canceled: "#dc2626",
    Returned: "#dc2626",
    Expired: "#dc2626",
    Pending: "#C2C2C2",
    Inprogress: "#EAB308",
    Completed: "#2C9741",
    Confirmed: "#2C9741",
    Paid: "#2C9741",
    Fullfield: "#2C9741",
  };
  export { statusColors };