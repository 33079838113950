import { Tooltip } from "antd";
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import {
  HiOutlineClipboardDocumentList,
  HiOutlineClipboardDocumentCheck,
  HiOutlineClipboardDocument,
} from "react-icons/hi2";
import UpdateLocation from "./UpdateLocation";
import { FiEdit } from "react-icons/fi";
import UpdateStatus from "./UpdateStatus";
import UpdateMake from "./UpdateMake";
import { statusColors } from "../../../../utils/statusColors";

export default function VehicleHeader({ vehicle, editable, vehicleId }: any) {
  const [locationModal, setLocationModal] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [makeModal, setMakeModal] = useState<boolean>(false);
  return (
    <div className="flex justify-between border-b">
      <div className="p-4">
        <div className="flex text-lg">
          <div className="font-bold uppercase">{vehicle?.name}</div>
          <div className="flex items-center px-2" style={{color: `${statusColors[vehicle?.status]}`}}>
            [ {vehicle?.status}
            {editable && (
              <button onClick={() => setStatusModal(true)} className="mx-1">
                <FiEdit size={16} />
              </button>
            )}
            ]
          </div>
        </div>
        <div className="">CHASSIS: {vehicle?.chassis}</div>
        <div className="">
          {vehicle?.make?.name} {vehicle?.model?.name}
          {editable && (
            <button onClick={() => setMakeModal(true)} className="mx-1">
              <FiEdit size={16} />
            </button>
          )}
        </div>
      </div>

      <div className="p-4 flex flex-col items-end">
        <div className="flex">
          <Tooltip title="Spec Sheet">
            <div className="bg-blue-600 p-2 rounded-md">
              <HiOutlineClipboardDocumentList color="#FFF" size={23} />
            </div>
          </Tooltip>
          <Tooltip title="Inspection Sheet">
            <div className="bg-green-600 p-2 rounded-md ml-2">
              <HiOutlineClipboardDocumentCheck color="#FFF" size={23} />
            </div>
          </Tooltip>
          <Tooltip title="Contract">
            <div className="bg-green-600 p-2 rounded-md ml-2">
              <HiOutlineClipboardDocument color="#FFF" size={23} />
            </div>
          </Tooltip>
        </div>
        <button
          onClick={() => setLocationModal(true)}
          className="flex items-center mt-1"
        >
          <div className="flex mr-1 items-center">
            <span className="mr-1">{vehicle?.country?.flag}</span>
            <span>{vehicle?.region?.name}</span>
          </div>

          <BsChevronDown />
        </button>
      </div>
      <UpdateLocation
        isVisible={locationModal}
        setVisible={setLocationModal}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
      />
      <UpdateStatus
        isVisible={statusModal}
        setVisible={setStatusModal}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
      />

      <UpdateMake
        isVisible={makeModal}
        setVisible={setMakeModal}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
      />
    </div>
  );
}
