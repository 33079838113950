const standard: any = {
  a1: "STANDARD A1",
  a2: "STANDARD A2",
  a3: "STANDARD A3",
  a4: "STANDARD A4",
  a5: "STANDARD A5",
  a6: "STANDARD A6",
  a7: "STANDARD A7",
  a8: "STANDARD A8",
  a9: "STANDARD A9",
  a10: "STANDARD A10",
  a11: "STANDARD A11",
  a12: "STANDARD A12",
};

export default standard;
