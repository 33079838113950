import { useState } from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createModel } from "../../../../api/models";

const CreateModel = ({ makeId, makeName }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isActive, setActive] = useState<boolean>(true);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createModel,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["makes", makeId] });
      setOpen(false);
    },
  });

  const onSubmit = async () => {
    const { name, count } = await form.validateFields();
    const data = {
      name,
      count: Number(count),
      make_id: Number(makeId),
      make_name: makeName,
      is_active: isActive,
    };
    mutate({ data });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      <Modal
        title={`Create Model : ${makeName}`}
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="createModel"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createModel"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Model Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter model name",
                  },
                ]}
              >
                <Input placeholder="Model Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="count"
                label="Vehicles Count"
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle count",
                  },
                ]}
              >
                <Input placeholder="Vehicles Count" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Checkbox
                defaultChecked={isActive}
                onChange={() => setActive(!isActive)}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateModel;
