import { FiEdit } from "react-icons/fi";
import UpdateDetailsOne from "./UpdateDetailsOne";
import { useState } from "react";

export default function VehicleDetailsOne({
  vehicle,
  editable,
  vehicleId,
}: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  return (
    <div className="p-2">
      <div className="flex justify-between items-center border-b bg-gray-100 p-2">
        <div className="">Vehicle Details</div>
        {editable && (
          <button onClick={() => setVisible(true)} className="">
            <FiEdit />
          </button>
        )}
      </div>
      <div className="">
        <DetailItem title={"Chassis"} value={vehicle?.chassis || "-"} />
        <DetailItem title={"Man. Year"} value={vehicle?.myear || "-"} />
        <DetailItem title={"Reg. Year"} value={vehicle?.ryear || "-"} />
        <DetailItem title={"Transmission"} value={vehicle?.transmission || "-"} />
        <DetailItem title={"Body Type"} value={vehicle?.body_type || "-"} />
        <DetailItem title={"Fuel Type"} value={vehicle?.fuel_type || "-"} />
        <DetailItem title={"Steering"} value={vehicle?.steering || "-"} />
        <DetailItem title={"Exterior Color"} value={vehicle?.ex_color || "-"} />
        <DetailItem title={"Interior Color"} value={vehicle?.in_color || "-"} />
      </div>
      <UpdateDetailsOne
        isVisible={isVisible}
        setVisible={setVisible}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
      />
    </div>
  );
}

function DetailItem({ title, value }: any) {
  return (
    <div className="flex justify-between items-center border-t p-2">
      <div className="text-xs">{title}</div>
      <div className="text-xs text-gray-500">{value}</div>
    </div>
  );
}
