import { useQuery } from "@tanstack/react-query";
import ManageWrapper from "..";
import Something from "../../../components/common/Something";
import CreateCountry from "./components/CreateCountry";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { BsEye } from "react-icons/bs";
import UpdateCountry from "./components/UpdateCountry";
import { useState } from "react";
import DeleteCountry from "./components/DeleteCountry";
import DetailsPanel from "./components/DetailsPanel";
import { fetchInquiries } from "../../../api/inquiries";
import { FiUser } from "react-icons/fi";

export default function AllInquiries() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const { data, isError } = useQuery({
    queryKey: ["inquiries"],
    queryFn: fetchInquiries,
  });
  return (
    <ManageWrapper
      title={"Inquiries"}
      subtitle={"List of all inquiries"}
      actions={
        <>
          <CreateCountry />
        </>
      }
    >
      <div className="mb-16">
        {isError ? (
          <div className="py-2">
            <Something />
          </div>
        ) : (
          <div className="">
            <div className="flex justify-between border-b items-center bg-gray-100 p-2">
              <div className="flex justify-center">
                <div className="w-12">S/N</div>
                <div className="w-44">Name</div>
                <div className="w-40">Mobile</div>
                <div className="w-44">Email</div>
                <div className="w-72">Vehicle</div>
              </div>
              <div className="">Actions</div>
            </div>
            {data?.map((item: any, index: number) => (
              <div className="flex justify-between border-b items-center p-2">
                <div className="flex justify-center">
                  <div className="w-12">{index + 1}</div>
                  <div className="w-44 flex items-center">
                    <div className="">
                      {item?.user_id ? (
                        <FiUser color="#22c55e" />
                      ) : (
                        <FiUser color="#ef4444" />
                      )}
                    </div>
                    <div className="mx-2">{item?.name}</div>
                  </div>
                  <div className="w-40">{item?.mobile || "-"}</div>
                  <div className="w-44">{item?.email || "-"}</div>
                  <div className="w-72">
                    {item?.vehicle?.name || ""} {" - "}{" "}
                    {item?.vehicle?.chassis || ""}
                  </div>
                </div>
                <div className="flex">
                  <button
                    onClick={() => {
                      setSelected(item);
                      setUpdateModal(true);
                    }}
                    className="border bg-yellow-100 rounded-md p-2 mr-1"
                  >
                    <FiEdit />
                  </button>
                  <button
                    onClick={() => {
                      setSelected(item);
                      setDeleteModal(true);
                    }}
                    className="border bg-red-100 rounded-md p-2 mr-1"
                  >
                    <RiDeleteBin4Line />
                  </button>
                  <button
                    onClick={() => {
                      setSelected(item);
                      setDetailModal(true);
                    }}
                    className="border bg-blue-100 rounded-md p-2"
                  >
                    <BsEye />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        <UpdateCountry
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DeleteCountry
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DetailsPanel
          isVisible={detailModal}
          setVisible={setDetailModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
