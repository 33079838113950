import { useState } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import UpdatePublish from "./UpdatePublish";
import UpdateDeal from "./UpdateDeal";

export default function DealWidget({ is_on_sale, vehicleId }: any) {
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  return (
    <div className="mb-4">
      <div className="flex justify-between items-center border-b border-t bg-gray-100 px-2 py-3">
        <div className="">Make it on deal</div>
        <button onClick={() => setUpdateModal(true)} className="">
          <FiEdit />
        </button>
      </div>
      <div className="flex justify-between p-2 text-xs">
        <div className="">Make it on deal?</div>
        <div className="">
          {is_on_sale ? (
            <div className="flex items-center">
              <BsCheck2Square color="#16a34a" />
              <span className="ml-1 text-green-600">YES</span>
            </div>
          ) : (
            <div className="flex items-center">
              <AiOutlineCloseSquare color="#dc2626" />
              <span className="ml-1 text-red-600">NO</span>
            </div>
          )}
        </div>
      </div>
      <UpdateDeal
        isVisible={updateModal}
        setVisible={setUpdateModal}
        is_on_sale={is_on_sale}
        vehicleId={vehicleId}
      />
    </div>
  );
}
