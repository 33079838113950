import { useQuery } from "@tanstack/react-query";
import { fetchBrands } from "../../../api/brands";
import Something from "../../../components/common/Something";
import CreateBrand from "./components/CreateBrand";
import ManageWrapper from "..";
import { useState } from "react";
import UploadImage from "./components/UploadImage";
import UpdateBrand from "./components/UpdateBrand";
import DeleteBrand from "./components/DeleteBrand";
import BrandWidget from "./components/BrandWidget";

export default function AllBrands() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { data, isError } = useQuery({
    queryKey: ["brands"],
    queryFn: fetchBrands,
  });

  const handleSelect = (item: any) => {
    setSelected(item);
  };

  return (
    <ManageWrapper
      title={"Brands"}
      subtitle={"List of all brands"}
      actions={<CreateBrand />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-2">
            {data?.map((item: any) => (
              <BrandWidget
                item={item}
                setSelected={setSelected}
                setUploadModal={setUploadModal}
                setUpdateModal={setUpdateModal}
                setDeleteModal={setDeleteModal}
              />
            ))}
          </div>
        )}

        <UpdateBrand
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />

        <UploadImage
          isVisible={uploadModal}
          setVisible={setUploadModal}
          selected={selected}
          setSelected={handleSelect}
        />

        <DeleteBrand
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
