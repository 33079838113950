import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createSupplier = async ({ data }: any) => {
  const response = await postData({ data, uri: "suppliers" });
  return response;
};

const fetchSuppliers = async () => {
  const response = await fetchData({ uri: "suppliers" });
  return response;
};

const fetchSupplier = async ({ id }: any) => {
  const response = await fetchData({ uri: `suppliers/${id}` });
  return response;
};

const updateSupplier = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `suppliers/${id}` });
  return response;
};

const uploadImage = async ({ id, file }: any) => {
  const response = await uploadFile({
    file,
    uri: `suppliers/${id}/logo`,
  });
  return response;
};

const deleteSupplier = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `suppliers/${id}` });
  return response;
};

export {
  createSupplier,
  updateSupplier,
  uploadImage,
  fetchSuppliers,
  fetchSupplier,
  deleteSupplier,
};
