import { useQuery } from "@tanstack/react-query";
import ManageWrapper from "..";
import Something from "../../../components/common/Something";
import CreateDiscount from "./components/CreateDiscount";
import UpdateCustomer from "./components/UpdateDiscount";
import { useState } from "react";
import DeleteCustomer from "./components/DeleteDiscount";
import { Table } from "antd";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import { fetchDiscounts } from "../../../api/discounts";

export default function AllDiscounts() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { data, refetch, isFetching, isError } = useQuery({
    queryKey: ["discounts"],
    queryFn: fetchDiscounts,
  });

  const columns: any = [
    {
      title: "S/N",
      width: 220,
      render: (record: any, _: any, index: number) => (
        <div className="">{index + 1}</div>
      ),
    },
    {
      title: "Name",
      width: 220,
      render: (record: any) => <div className="">{record?.name || "-"}</div>,
    },
    {
      title: "Percentage",
      width: 220,
      render: (record: any) => <div className="">{record?.percent} %</div>,
    },
    {
      title: "Description",
      width: 140,
      render: (record: any) => (
        <div className="">{record?.description || "-"}</div>
      ),
    },
    {
      title: "Active",
      width: 140,
      render: (record: any) => (
        <div className="">{record?.is_active ? "YES" : "NO"}</div>
      ),
    },
    {
      title: "Actions",
      align: "right" as "right",
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected(record);
              setUpdateModal(true);
            }}
            className="border bg-yellow-100 rounded-md p-2 mr-1"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => {
              setSelected(record);
              setDeleteModal(true);
            }}
            className="border bg-red-100 rounded-md p-2 mr-1"
          >
            <RiDeleteBin4Line />
          </button>
          <Link
            to={`/manage/suppliers/${record?.id}`}
            className="border bg-blue-100 rounded-md p-2"
          >
            <BsEye />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <ManageWrapper
      title={"Discounts"}
      subtitle={"List of all discounts"}
      actions={
        <>
          <CreateDiscount />
        </>
      }
    >
      <div className="mb-16">
        {isError ? (
          <div className="py-2">
            <Something refetch={refetch} isFetching={isFetching} />
          </div>
        ) : (
          <div className="">
            <Table columns={columns} dataSource={data} size="small" />
          </div>
        )}
        <UpdateCustomer
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DeleteCustomer
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
