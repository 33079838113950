import { useQuery } from "@tanstack/react-query";
import ManageWrapper from "..";
import Something from "../../../components/common/Something";
import CreateReview from "./components/CreateReview";
import UpdateCustomer from "./components/UpdateDiscount";
import { useState } from "react";
import DeleteCustomer from "./components/DeleteDiscount";
import { Table } from "antd";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import { fetchReviews } from "../../../api/reviews";

export default function AllReviews() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { data, refetch, isFetching, isError } = useQuery({
    queryKey: ["reviews"],
    queryFn: fetchReviews,
  });

  const columns: any = [
    {
      title: "S/N",
      width: 220,
      render: (record: any, _: any, index: number) => (
        <div className="">{index + 1}</div>
      ),
    },
    {
      title: "Product",
      width: 220,
      render: (record: any) => (
        <div className="flex">
          <div className="">
            <img src={record?.product?.thumbnail} alt="" />
          </div>
          <div className="flex flex-col">
            <div className="">{record?.product?.name}</div>
            <div className="">{record?.product?.price}</div>
          </div>
        </div>
      ),
    },
    {
      title: "User",
      width: 220,
      render: (record: any) => (
        <div className="flex">
          <div className="">
            <img src={record?.user?.avatar} alt="" />
          </div>
          <div className="flex flex-col">
            <div className="">{record?.user?.name}</div>
            <div className="">{record?.user?.mobile}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Rating",
      width: 140,
      render: (record: any) => <div className="">{record?.rating || "-"}</div>,
    },
    {
      title: "Actions",
      align: "right" as "right",
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected(record);
              setUpdateModal(true);
            }}
            className="border bg-yellow-100 rounded-md p-2 mr-1"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => {
              setSelected(record);
              setDeleteModal(true);
            }}
            className="border bg-red-100 rounded-md p-2 mr-1"
          >
            <RiDeleteBin4Line />
          </button>
          <Link
            to={`/manage/suppliers/${record?.id}`}
            className="border bg-blue-100 rounded-md p-2"
          >
            <BsEye />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <ManageWrapper
      title={"Reviews"}
      subtitle={"List of all reviews"}
      actions={
        <>
          <CreateReview />
        </>
      }
    >
      <div className="mb-16">
        {isError ? (
          <div className="py-2">
            <Something refetch={refetch} isFetching={isFetching} />
          </div>
        ) : (
          <div className="">
            <Table columns={columns} dataSource={data} size="small" />
          </div>
        )}
        <UpdateCustomer
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DeleteCustomer
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
