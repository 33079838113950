import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createBrand = async ({ data }: any) => {
  const response = await postData({ data, uri: "brands" });
  return response;
};

const fetchBrands = async () => {
  const response = await fetchData({ uri: "brands" });
  return response;
};

const fetchBrandModels = async ({ id }: any) => {
  const response = await fetchData({ uri: `brands/${id}` });
  return response;
};

const updateBrand = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `brands/${id}` });
  return response;
};

const uploadImage = async ({ id, file, make }: any) => {
  const response = await uploadFile({ file, uri: `brands/${id}/image?make=${make}` });
  return response;
};

const deleteBrand = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `brands/${id}` });
  return response;
};

export {
  createBrand,
  updateBrand,
  uploadImage,
  fetchBrands,
  fetchBrandModels,
  deleteBrand,
};
