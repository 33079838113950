import { useQuery } from "@tanstack/react-query";
import { fetchMakes } from "../../../api/makes";
import Something from "../../../components/common/Something";
import CreateMake from "./components/CreateMake";
import ManageWrapper from "..";
import { useState } from "react";
import UploadImage from "./components/UploadImage";
import UpdateMake from "./components/UpdateMake";
import DeleteMake from "./components/DeleteMake";
import MakeWidget from "./components/MakeWidget";

export default function AllMakes() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { data, isError } = useQuery({
    queryKey: ["makes"],
    queryFn: fetchMakes,
  });

  const handleSelect = (item: any) => {
    setSelected(item);
  };

  return (
    <ManageWrapper
      title={"Makes"}
      subtitle={"List of all makes"}
      actions={<CreateMake />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-2">
            {data?.map((item: any) => (
              <MakeWidget
                item={item}
                setSelected={setSelected}
                setUploadModal={setUploadModal}
                setUpdateModal={setUpdateModal}
                setDeleteModal={setDeleteModal}
              />
            ))}
          </div>
        )}

        <UpdateMake
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />

        <UploadImage
          isVisible={uploadModal}
          setVisible={setUploadModal}
          selected={selected}
          setSelected={handleSelect}
        />

        <DeleteMake
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
