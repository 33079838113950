import { useQuery } from "@tanstack/react-query";
import ManageWrapper from "..";
import Something from "../../../components/common/Something";
import placeholder from "../../../assets/images/placeholder.png";
import CreateProduct from "./components/CreateProduct";
import UpdateCountry from "./components/UpdateCountry";
import { useState } from "react";
import DeleteCountry from "./components/DeleteCountry";
import DetailsPanel from "./components/DetailsPanel";
import { Link } from "react-router-dom";
import formatMoneyLong from "../../../utils/formatMoneyLong";
import { fetchProducts } from "../../../api/products";

export default function AllProducts() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const { data: products, isError } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts({ cursor: 0 }),
  });
  return (
    <ManageWrapper
      title={"Products"}
      subtitle={"List of all Products"}
      actions={
        <>
          <CreateProduct />
        </>
      }
    >
      <div className="mb-16">
        {isError ? (
          <div className="py-2">
            <Something />
          </div>
        ) : (
          <div className="">
            <div className="flex justify-between border-b items-center bg-gray-100 p-2">
              <div className="flex justify-center">
                <div className="w-8">S/N</div>
                <div className="w-24 mr-4">Thumbnail</div>
                <div className="w-64">Name</div>
                <div className="w-40">Status</div>
                <div className="w-24">Views</div>
                <div className="w-24">Price</div>
              </div>
              <div className="">Hits</div>
            </div>
            {products?.data?.map((item: any, index: number) => (
              <Link
                to={`/manage/products/${item?.id}`}
                className="flex justify-between border-b items-center p-2 hover:bg-gray-50"
              >
                <div className="flex justify-center">
                  <div className="w-8 text-center">{index + 1}.</div>
                  <div className="w-24 flex justify-center items-center bg-gray-100 mr-4">
                    <img src={item?.thumbnail || placeholder} width={60} />
                  </div>
                  <div className="w-64">
                    <div className="">Name: {item?.name}</div>
                    <div className="text-gray-500">
                      {item?.myear} {item?.name}
                    </div>
                  </div>
                  <div className="w-40">
                    <div className="">Status: {item?.status}</div>
                    <div className="text-gray-500">
                      Published :
                      {item?.is_published ? (
                        <span className="text-green-600 px-1">YES</span>
                      ) : (
                        <span className="text-red-600 px-1">NO</span>
                      )}
                    </div>
                  </div>
                  <div className="w-24">
                    {item?.views ? (
                      <div className="flex flex-col">
                        <div className="">{formatMoneyLong(item?.views)}</div>
                        <div className="">Km</div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="w-24">
                    {formatMoneyLong(item?.price) || "-"}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
        <UpdateCountry
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DeleteCountry
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DetailsPanel
          isVisible={detailModal}
          setVisible={setDetailModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
