export default function Invoice({ width, height, color }: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0004 17.88C11.7504 17.88 11.4904 17.79 11.2904 17.61L8.71044 15.35C7.74044 14.5 7.61044 13.04 8.42044 12.03C9.24044 11.01 10.7004 10.82 11.7504 11.61L12.0004 11.8L12.2604 11.6C13.3104 10.81 14.7704 11 15.5904 12.02C16.4004 13.03 16.2704 14.49 15.3004 15.34L12.7204 17.6C12.5104 17.79 12.2504 17.88 12.0004 17.88ZM9.69044 14.22L12.0004 16.24L14.3104 14.22C14.6804 13.9 14.7304 13.35 14.4204 12.97C14.1104 12.58 13.5604 12.51 13.1604 12.81L12.4504 13.34C12.1804 13.54 11.8204 13.54 11.5504 13.34L10.8404 12.81C10.4504 12.51 9.89044 12.58 9.58044 12.97C9.28044 13.35 9.33044 13.9 9.69044 14.22Z"
        fill={color}
      />
      <path
        d="M17 22.75H7C2.59 22.75 1.25 21.41 1.25 17V7C1.25 2.59 2.59 1.25 7 1.25H8.5C10.25 1.25 10.8 1.82 11.5 2.75L13 4.75C13.33 5.19 13.38 5.25 14 5.25H17C21.41 5.25 22.75 6.59 22.75 11V17C22.75 21.41 21.41 22.75 17 22.75ZM7 2.75C3.42 2.75 2.75 3.43 2.75 7V17C2.75 20.57 3.42 21.25 7 21.25H17C20.58 21.25 21.25 20.57 21.25 17V11C21.25 7.43 20.58 6.75 17 6.75H14C12.72 6.75 12.3 6.31 11.8 5.65L10.3 3.65C9.78 2.96 9.62 2.75 8.5 2.75H7Z"
        fill={color}
      />
    </svg>
  );
}
