import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/common/Something";
import { useParams } from "react-router";
import BackToolNav from "../../../components/toolnav/BackToolNav";
import { useState } from "react";
import { Button } from "antd";
import { FiEdit } from "react-icons/fi";
import { HiOutlineChevronDown } from "react-icons/hi";
import ManageWrapper from "..";
import { AiOutlineMail } from "react-icons/ai";
import DetailsHeader from "./components/DetailsHeader";
import { fetchSeller } from "../../../api/sellers";

export default function OneSeller() {
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const { id } = useParams();
  const { data: seller, isError } = useQuery({
    queryKey: ["sellers", id],
    queryFn: () => fetchSeller({ id }),
  });

  return (
    <ManageWrapper
      title={"Seller"}
      subtitle={"Seller details, orders and addresses"}
      actions={
        <div className="flex items-center">
          <div className="mr-2">
            <div className="flex items-center justify-center">
              <div className="">
                {seller?.is_active ? (
                  <div className="p-1 text-green-600 rounded-md text-xs">
                    Active
                  </div>
                ) : (
                  <div className="p-1  text-red-600 rounded-md text-xs">
                    In Active
                  </div>
                )}
              </div>
              <div className="">
                <HiOutlineChevronDown
                  color={`${seller?.is_active ? "#16a34a" : "#dc2626"}`}
                />
              </div>
            </div>
          </div>
          <Button onClick={() => setUpdateModal(true)}>
            <FiEdit />
          </Button>
        </div>
      }
      toolnav={<BackToolNav />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <div className="">
            <DetailsHeader customer={seller} />
            <div className="grid grid-cols-12 gap-x-2 mt-4">
              <div className="col-span-12 sm:col-span-4 border">
                <div className="border-b p-2">Personal Information</div>
                <div className="flex">
                  <div className="flex p-2">
                    <div className=" justify-start mr-2">
                      <AiOutlineMail />
                    </div>
                    <div className="flex flex-1">
                      <div className="flex flex-col justify-center">
                        <div className="">Email</div>
                        <div className="font-bold">{seller?.email}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-8 border">
                <div className="border-b p-2">Seller Address</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ManageWrapper>
  );
}
