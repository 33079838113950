import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createProduct = async ({ data }: any) => {
  const response = await postData({ data, uri: "products" });
  return response;
};

const fetchProducts = async ({ cursor }: any) => {
  const response = await fetchData({
    uri: `products?cursor=${cursor}`,
  });
  return response;
};

const fetchProduct = async ({ id }: any) => {
  const response = await fetchData({ uri: `products/${id}` });
  return response;
};

const updateProduct = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `products/${id}` });
  return response;
};

const updateProductDetails = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${id}/update/details`,
  });
  return response;
};

const updateProductSpecs = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${id}/update/specs`,
  });
  return response;
};

const updateProductFeatures = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${id}/update/features`,
  });
  return response;
};

const updateProductLocation = async ({ id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${id}/update/location`,
  });
  return response;
};

const updateProductStatus = async ({ product_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${product_id}/update/status`,
  });
  return response;
};

const updateProductPrice = async ({ product_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${product_id}/update/price`,
  });
  return response;
};

const updateProductDescription = async ({ product_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${product_id}/update/description`,
  });
  return response;
};

const updateProductPublish = async ({ product_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${product_id}/update/publish`,
  });
  return response;
};

const updateProductOnsale = async ({ product_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${product_id}/update/onsale`,
  });
  return response;
};

const updateProductForsale = async ({ product_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `products/${product_id}/update/forsale`,
  });
  return response;
};

const uploadProductImage = async ({
  id,
  file,
  position,
  category,
  is_main,
}: any) => {
  const response = await uploadFile({
    file,
    uri: `products/${id}/update/images?position=${position}&category=${category}&is_main=${is_main}`,
  });
  return response;
};

const uploadVehicleFile = async ({ id, data }: any) => {
  const response = await uploadFile({
    data,
    uri: `products/${id}/update/files`,
  });
  return response;
};

const deleteProduct = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `products/${id}` });
  return response;
};

//SPECS

const addProductSpec = async ({ data }: any) => {
  const response = await postData({
    data,
    uri: `specs`,
  });
  return response;
};

const updateProductSpec = async ({ spec_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `specs/${spec_id}`,
  });
  return response;
};

const deleteProductSpec = async ({ spec_id }: any) => {
  const response = await deleteData({
    uri: `specs/${spec_id}`,
  });
  return response;
};

// DETAILS
const addProductDetail = async ({ data }: any) => {
  const response = await postData({
    data,
    uri: `details`,
  });
  return response;
};

const updateProductDetail = async ({ detail_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `details/${detail_id}`,
  });
  return response;
};

const deleteProductDetail = async ({ detail_id }: any) => {
  const response = await deleteData({
    uri: `details/${detail_id}`,
  });
  return response;
};

// HOWTOS
const addProductHowto = async ({ data }: any) => {
  const response = await postData({
    data,
    uri: `howtos`,
  });
  return response;
};

const updateProductHowto = async ({ howto_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `howtos/${howto_id}`,
  });
  return response;
};

const deleteProductHowto = async ({ howto_id }: any) => {
  const response = await deleteData({
    uri: `howtos/${howto_id}`,
  });
  return response;
};

// FITMENTS
const addProductFitment = async ({ data }: any) => {
  const response = await postData({
    data,
    uri: `fitments`,
  });
  return response;
};

const updateProductFitment = async ({ fitment_id, data }: any) => {
  const response = await patchData({
    data,
    uri: `fitments/${fitment_id}`,
  });
  return response;
};

const deleteProductFitment = async ({ fitment_id }: any) => {
  const response = await deleteData({
    uri: `fitments/${fitment_id}`,
  });
  return response;
};

export {
  createProduct,
  updateProduct,
  fetchProducts,
  fetchProduct,
  deleteProduct,
  updateProductSpec,
  updateProductSpecs,
  updateProductFeatures,
  updateProductLocation,
  updateProductPublish,
  updateProductOnsale,
  updateProductForsale,
  updateProductStatus,
  updateProductPrice,
  updateProductDetails,
  updateProductDescription,
  uploadProductImage,
  uploadVehicleFile,
  addProductSpec,
  deleteProductSpec,
  addProductDetail,
  updateProductDetail,
  deleteProductDetail,
  addProductHowto,
  updateProductHowto,
  deleteProductHowto,
  addProductFitment,
  updateProductFitment,
  deleteProductFitment,
};
