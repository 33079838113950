const getImageCategories = ({ images }: any) => {
  const data = images?.reduce((result: any, obj: any) => {
    if (!result[obj.category]) {
      result[obj.category] = [];
    }

    result[obj.category].push(obj);

    return result;
  }, {});

  return data;
};

export { getImageCategories };
