import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/common/Something";
import { useParams } from "react-router";
import BackToolNav from "../../../components/toolnav/BackToolNav";
import { useState } from "react";
import { Button } from "antd";
import ManageWrapper from "..";
import ProductHeader from "./components/ProductHeader";
import ProductImages from "./components/ProductImages";
import VehicleDetailsOne from "./components/VehicleDetailsOne";
import PublishWidget from "./components/PublishWidget";
import PriceWidget from "./components/PriceWidget";
import { fetchProduct } from "../../../api/products";
import { BsPostcard } from "react-icons/bs";
import CreatePost from "./components/CreatePost";
import ProductDescription from "./components/ProductDescription";
import ProductSpecs from "./components/ProductSpecs";
import ProductDetails from "./components/ProductDetails";
import ProductHowtos from "./components/ProductHowtos";
import ProductFitments from "./components/ProductFitments";

export default function OneProduct() {
  const [postModal, setPostModal] = useState<boolean>(false);
  const { id } = useParams();
  const { data: product, isError } = useQuery({
    queryKey: ["products", id],
    queryFn: () => fetchProduct({ id }),
  });

  return (
    <ManageWrapper
      title={"Product"}
      subtitle={"Product details"}
      actions={
        <div className="flex items-center">
          <Button onClick={() => setPostModal(true)}>
            <BsPostcard />
          </Button>
        </div>
      }
      toolnav={<BackToolNav />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 sm:col-span-9 border">
                <ProductHeader
                  product={product}
                  editable={true}
                  productId={id}
                />
                <ProductImages
                  product={product}
                  editable={true}
                  productId={id}
                />

                <div className="grid grid-cols-12">
                  <div className="col-span-12 sm:col-span-12">
                    <ProductDescription
                      description={product?.description}
                      productId={id}
                      editable={true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-12 sm:col-span-12">
                    <VehicleDetailsOne
                      product={product}
                      editable={true}
                      vehicleId={id}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <ProductSpecs
                    product={product}
                    productId={id}
                    editable={true}
              />
                </div>

                <div className="mb-4">
                  <ProductDetails
                    product={product}
                    productId={id}
                    editable={true}
                  />
                </div>

                <div className="mb-4">
                  <ProductFitments
                    product={product}
                    productId={id}
                    editable={true}
                  />
                </div>


                <div className="mb-4">
                  <ProductHowtos
                    product={product}
                    productId={id}
                    editable={true}
                  />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3 border">
                <PublishWidget
                  is_published={product?.is_published}
                  is_on_sale={product?.is_on_sale}
                  is_for_sale={product?.is_for_sale}
                  productId={id}
                />
                <PriceWidget product={product} productId={id} />
              </div>
            </div>
          </>
        )}
        <CreatePost
          isVisible={postModal}
          setVisible={setPostModal}
          product={product}
        />
      </div>
    </ManageWrapper>
  );
}
