import { Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiOutlineCheck } from "react-icons/ai";
import { updateOrderStatus } from "../../../api/orders";

const UpdateStatus = ({ isVisible, setVisible, order, orderId }: any) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateOrderStatus,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: (response) => {
      if (response?.success) {
        message.success("Updated Successfully");
      } else {
        message.error(`${response?.message}`);
      }
      queryClient.invalidateQueries({ queryKey: ["orders", orderId] });
      setVisible(false);
    },
  });

  const onSubmit = async ({ status }: any) => {
    const data = { status };
    mutate({ order_id: orderId, data });
  };

  const statuses = [
    { id: 1, is_sold: false, value: "Active" },
    { id: 2, is_sold: false, value: "Confimed" },
    { id: 3, is_sold: false, value: "Fullfield" },
    { id: 4, is_sold: true, value: "Paid" },
    { id: 5, is_sold: true, value: "Canceled" },
    { id: 6, is_sold: true, value: "Returned" },
  ];

  return (
    <>
      <Modal
        title="Update Status"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <div className="">
          {statuses?.map((status: any, index: number) => (
            <button
              className={`flex items-center bg-gray-100 p-2 mb-2 border w-full ${
                order?.status === status?.value ? "bg-green-100" : ""
              }`}
              onClick={() => {
                onSubmit({ status: status?.value });
              }}
              key={index}
            >
              <div className="flex justify-center items-center w-8 h-8 border border-gray-400">
                {order?.status === status?.value ? (
                  <div className="">
                    <AiOutlineCheck size={20} />
                  </div>
                ) : null}
              </div>
              <div className="ml-2">{status?.value}</div>
            </button>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default UpdateStatus;
