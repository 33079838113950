import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createOption = async ({ data }: any) => {
  const response = await postData({ data, uri: "options" });
  return response;
};

const fetchOptions = async () => {
  const response = await fetchData({ uri: "options" });
  return response;
};

const fetchOption = async ({ id }: any) => {
  const response = await fetchData({ uri: `options/${id}` });
  return response;
};

const updateOption = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `options/${id}` });
  return response;
};


const uploadIcon = async ({ id, file, name }: any) => {
  const response = await uploadFile({
    file,
    uri: `options/${id}/icon?name=${name}`,
  });
  return response;
};

const deleteOption = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `options/${id}` });
  return response;
};

export {
  createOption,
  updateOption,
  fetchOptions,
  fetchOption,
  deleteOption,
  uploadIcon,
};
