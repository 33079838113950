import { Outlet } from "react-router";
import logo from "../assets/images/tlogowhite.png";
import SideMenu from "../components/common/SideMenu";
export default function MainLayout() {
  return (
    <div className="flex min-h-screen">
      <div className="w-24">
        <div className="h-16 bg-pur-800 flex justify-center items-center">
          <img src={logo} alt="" width={40} />
        </div>
        <div className="border-r min-h-full">
          <SideMenu />
        </div>
      </div>
      <div className="flex-1">
        <div className="flex items-center justify-between h-16 bg-gray-700 px-4">
          <div className="flex items-center">
            <div className=" text-white"><span className="font-bold">DOSH</span><span className="font-thin">CARS</span></div>
            <div className="border-l pl-2 ml-2 text-white font-thin">
              Analytics
            </div>
          </div>
          <div className="text-white">DC</div>
        </div>

        <div className=" h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
