const vimagePositions: any = ({ vehicle, placeholder }: any) => {
  let E1 = "";
  let E2 = "";
  let E3 = "";
  let E4 = "";
  let I1 = "";
  let I2 = "";
  let I3 = "";
  let I4 = "";
  let M1 = "";
  let M2 = "";
  let M3 = "";
  let M4 = "";
  let O1 = "";
  let O2 = "";

  if (vehicle?.vimages) {
    vehicle?.vimages?.forEach((image: any) => {
      switch (image?.position) {
        case "e1":
          E1 = image?.url;
          break;
        case "e2":
          E2 = image?.url;
          break;
        case "e3":
          E3 = image?.url;
          break;
        case "e4":
          E4 = image?.url;
          break;
        case "i1":
          I1 = image?.url;
          break;
        case "i2":
          I2 = image?.url;
          break;
        case "i3":
          I3 = image?.url;
          break;
        case "i4":
          I4 = image?.url;
          break;
        case "m1":
          M1 = image?.url;
          break;
        case "m2":
          M2 = image?.url;
          break;
        case "m3":
          M3 = image?.url;
          break;
        case "m4":
          M4 = image?.url;
          break;
        case "o1":
          O1 = image?.url;
          break;
        case "o2":
          O2 = image?.url;
          break;
      }
    });
  }

  return {
    e1: E1 || placeholder,
    e2: E2 || placeholder,
    e3: E3 || placeholder,
    e4: E4 || placeholder,
    i1: I1 || placeholder,
    i2: I2 || placeholder,
    i3: I3 || placeholder,
    i4: I4 || placeholder,
    m1: M1 || placeholder,
    m2: M2 || placeholder,
    m3: M3 || placeholder,
    m4: M4 || placeholder,
    o1: O1 || placeholder,
    o2: O2 || placeholder,
  };
};

export default vimagePositions;
