import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createDiscount = async ({ data }: any) => {
  const response = await postData({ data, uri: "discounts" });
  return response;
};

const fetchDiscounts = async () => {
  const response = await fetchData({ uri: "discounts" });
  return response;
};

const fetchDiscount = async ({ id }: any) => {
  const response = await fetchData({ uri: `discounts/${id}` });
  return response;
};

const updateDiscount = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `discounts/${id}` });
  return response;
};

const deleteDiscount = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `discounts/${id}` });
  return response;
};

export {
  createDiscount,
  updateDiscount,
  fetchDiscounts,
  fetchDiscount,
  deleteDiscount,
};
