import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createCategory = async ({ data }: any) => {
  const response = await postData({ data, uri: "categories" });
  return response;
};

const fetchCategories = async () => {
  const response = await fetchData({ uri: "categories" });
  return response;
};

const fetchCategory = async ({ id }: any) => {
  const response = await fetchData({ uri: `categories/${id}` });
  return response;
};

const updateCategory = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `categories/${id}` });
  return response;
};

const uploadImage = async ({ id, file, name }: any) => {
  const response = await uploadFile({
    file,
    uri: `categories/${id}/image?name=${name}`,
  });
  return response;
};

const deleteCategory = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `categories/${id}` });
  return response;
};

export {
  createCategory,
  updateCategory,
  fetchCategories,
  fetchCategory,
  uploadImage,
  deleteCategory,
};
