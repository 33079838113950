import { FiEdit } from "react-icons/fi";
import { useState } from "react";
import UpdateDescription from "./UpdateDescription";

export default function ProductDescription({
  description,
  productId,
  editable,
}: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  return (
    <div className="p-2">
      <div className="flex justify-between items-center border-b bg-gray-100 p-2">
        <div className="">Product Description</div>
        {editable && (
          <button onClick={() => setVisible(true)} className="">
            <FiEdit />
          </button>
        )}
      </div>
      <div className="p-2 text-gray-600 text-xs">
       {description}
      </div>
      <UpdateDescription
        isVisible={isVisible}
        setVisible={setVisible}
        description={description}
        productId={productId}
      />
    </div>
  );
}

