import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createProduct } from "../../../../api/products";
import { fetchBrands } from "../../../../api/brands";
import { fetchCategories } from "../../../../api/categories";
import { fetchInventories } from "../../../../api/inventories";
import { fetchDiscounts } from "../../../../api/discounts";
import { fetchSuppliers } from "../../../../api/suppliers";

const { Option } = Select;
const {TextArea} = Input

const CreateProduct: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [subcats, setSubcats] = useState<any>([]);

  const [brand, setBrand] = useState<string>("");


  const [category, setCategory] = useState<string>("");
  const [subcat, setSubcat] = useState<string>("");

  const queryClient = useQueryClient();

  const { data: brands } = useQuery({
    queryKey: ["brands"],
    queryFn: fetchBrands,
  });


  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });


  const { data: suppliers } = useQuery({
    queryKey: ["suppliers"],
    queryFn: fetchSuppliers,
  });


  const { data: inventories } = useQuery({
    queryKey: ["inventories"],
    queryFn: fetchInventories,
  });


  const { data: discounts } = useQuery({
    queryKey: ["discounts"],
    queryFn: fetchDiscounts,
  });

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createProduct,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["products"] });
      setOpen(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();

    const data = {
      name: values.name,
      brand,
      category,
      subcat,
      status: "Available",
      ...values,
      price: Number(values.price),
    };
    mutate({ data });
  };


  const handleBrand = (id: any) => {
    const brand = brands.filter((brand: any) => brand.id === id);
    setBrand(brand[0]?.name);
  };


  const handleCategory = (id: any) => {
    const category = categories.filter((category: any) => category.id === id);
    setCategory(category[0]?.name);
    setSubcats(category[0]?.subcats);
  };

  const handleSubcat = (id: any) => {
    const subcat = subcats.filter((subcat: any) => subcat.id === id);
    setSubcat(subcat[0]?.name);
  };

  const brandOptions = brands?.map((brand: any, index: number) => (
    <Option value={brand?.id} key={index}>
      {brand?.name}
    </Option>
  ));


  const supplierOption = suppliers?.map((model: any, index: number) => (
    <Option value={model?.id} key={index}>
      {model?.name}
    </Option>
  ));


  const categoryOptions = categories?.map((category: any, index: number) => (
    <Option value={category?.id} key={index}>
      {category?.name}
    </Option>
  ));


  const inventoryOptions = inventories?.map((inventory: any, index: number) => (
    <Option value={inventory?.id} key={index}>
      {inventory?.name} - {inventory?.quantity}
    </Option>
  ));

  const discountOptions = discounts?.map((discount: any, index: number) => (
    <Option value={discount?.id} key={index}>
      {discount?.percent} %
    </Option>
  ));

  
  const subcatOptions = subcats?.map((subcat: any, index: number) => (
    <Option value={subcat?.id} key={index}>
      {subcat?.name}
    </Option>
  ));

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      <Modal
        title="Create Product"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => form.resetFields()}>
            Clear
          </Button>,
          <Button
            key="submit"
            form="createProduct"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createProduct"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  {
                    required: true,
                    message: "Please enter Price",
                  },
                ]}
              >
                <Input placeholder="Price" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="category_id"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "Please select category",
                  },
                ]}
              >
                <Select
                  placeholder="Category"
                  onSelect={(e: any) => handleCategory(e)}
                >
                  {categoryOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="subcat_id"
                label="Subcategory"
                rules={[
                  {
                    required: true,
                    message: "Please enter subcategory",
                  },
                ]}
              >
                <Select
                  placeholder="Subcategory"
                  onSelect={(e: any) => handleSubcat(e)}
                >
                  {subcatOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="sku"
                label="SKU"
                rules={[
                  {
                    required: true,
                    message: "Please enter sku",
                  },
                ]}
              >
                <Input type="text" placeholder="SKU" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="supplier_id"
                label="Supplier"
                rules={[
                  {
                    required: true,
                    message: "Please select supplier",
                  },
                ]}
              >
                <Select
                  placeholder="Supplier"
                >
                  {supplierOption}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="brand_id"
                label="Brand"
                rules={[
                  {
                    required: true,
                    message: "Please select brand",
                  },
                ]}
              >
                <Select
                  placeholder="Brand"
                  onSelect={(e: any) => handleBrand(e)}
                >
                  {brandOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="inventory_id"
                label="Inventory"
                rules={[
                  {
                    required: true,
                    message: "Please select inventory",
                  },
                ]}
              >
                <Select
                  placeholder="Inventory"
                >
                  {inventoryOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="discount_id"
                label="Discount"
                rules={[
                  {
                    required: true,
                    message: "Please select discount",
                  },
                ]}
              >
                <Select
                  placeholder="Discount"
                >
                  {discountOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: false,
                    message: "Please select description",
                  },
                ]}
              >
                <TextArea placeholder="Description" />
              </Form.Item>
            </Col>
            </Row>

        </Form>
      </Modal>
    </>
  );
};

export default CreateProduct;
