import { FiList } from "react-icons/fi";
import { NavLink } from "react-router-dom";

export default function ManageWrapper({
  title,
  subtitle,
  actions,
  toolnav,
  children,
}: any) {
  const menus = [
    { id: 1, name: "Makes", link: "/manage/makes", icon: <FiList /> },
    { id: 2, name: "Customers", link: "/manage/customers", icon: <FiList /> },
    { id: 3, name: "Countries", link: "/manage/countries", icon: <FiList /> },
    { id: 4, name: "Branches", link: "/manage/branches", icon: <FiList /> },
    { id: 5, name: "Vehicles", link: "/manage/vehicles", icon: <FiList /> },
    { id: 6, name: "Inquiries", link: "/manage/inquiries", icon: <FiList /> },
    { id: 7, name: "Suppliers", link: "/manage/suppliers", icon: <FiList /> },
    { id: 8, name: "Sellers", link: "/manage/sellers", icon: <FiList /> },
    { id: 9, name: "Options", link: "/manage/options", icon: <FiList /> },
    { id: 1, name: "Brands", link: "/manage/brands", icon: <FiList /> },
    { id: 7, name: "Products", link: "/manage/products", icon: <FiList /> },
    { id: 3, name: "Categories", link: "/manage/categories", icon: <FiList /> },
    { id: 9, name: "Reviews", link: "/manage/reviews", icon: <FiList /> },
    { id: 8, name: "Posts", link: "/manage/posts", icon: <FiList /> },
    { id: 11, name: "Discounts", link: "/manage/discounts", icon: <FiList /> },
    { id: 12, name: "Inventories", link: "/manage/inventories", icon: <FiList /> },
  ];
  return (
    <div className="flex border m-2 rounded-md bg-white">
      <div className="border-r w-48">
        {menus.map((item: any, index: number) => (
          <NavLink
            to={item?.link}
            className={({ isActive }) =>
              isActive
                ? "border-r border-gray-700 bg-gray-50  flex items-center p-3"
                : "flex items-center p-3"
            }
          >
            <div className="">{item?.icon}</div>
            <div className="ml-1">{item?.name}</div>
          </NavLink>
        ))}
      </div>
      <div className="flex-1">
        <div className="py-3 flex justify-between items-center border-b px-2">
          <div className="flex flex-col">
            <div className="">
              <span className="font-normal">{title} :</span>{" "}
              <span className="font-thin">{subtitle}</span>
            </div>
          </div>
          <div className="">{actions}</div>
        </div>
        <div className="">
          <div className="">{toolnav}</div>
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
}
