import { Button, Checkbox, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { updateModel } from "../../../../api/models";

const UpdateModel = ({ isVisible, setVisible, make_id, selected }: any) => {
  const { id } = selected;
  const queryClient = useQueryClient();
  const [isActive, setActive] = useState<boolean>(selected?.is_active);

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateModel,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["makes", make_id],
      });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { name, count } = await form.validateFields();
    const data = {
      name,
      count: Number(count),
      is_active: isActive,
      make_id: Number(make_id),
    };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <>
      <Modal
        title="Update Model"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="updateModel"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="updateModel"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Model Name"
                initialValue={selected?.name}
                rules={[
                  {
                    required: true,
                    message: "Please enter model name",
                  },
                ]}
              >
                <Input placeholder="Model Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="count"
                label="Vehicles Count"
                initialValue={selected?.count}
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle count",
                  },
                ]}
              >
                <Input placeholder="Vehicles Count" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Checkbox
                defaultChecked={isActive}
                onChange={() => setActive(!isActive)}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateModel;
