import {  useState } from "react";
import placeholder from "../../../../assets/images/placeholder.png";
import UploadImage from "./UploadImage";
import positionImages from "../../../../utils/positionImages";
import ImageWidget from "./ImageWidget";
export default function VehicleImages({ vehicle, editable, vehicleId }: any) {
  const { f1, f2, s1, s2, b1, b2, i1, i2, i3, i4 } = positionImages({
    vehicle,
    placeholder,
  });

  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  return (
    <div className="grid grid-cols-12 gap-2  p-2">
      <div className="col-span-12 sm:col-span-6 bg-gray-100">
        <button
          onClick={() => {
            if (editable) {
              setSelected({ position: "f1" });
              setVisible(true);
            }
          }}
          className=""
        >
          <img src={f1} alt="" />
        </button>
      </div>
      <div className="col-span-12 sm:col-span-6">
        <div className="flex justify-between">
          <div className="">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300 mb-1">
              FRONT
            </div>
            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "f1" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={f1} width={150} alt="" />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "f2" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={f2} width={150} alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="ml-1">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300 mb-1">
              BACK
            </div>
            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "b1" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={b1} width={150} alt="" />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "b2" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={b2} width={150} alt=""/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300 mb-1">
              INTERIORS
            </div>
            <div className="flex">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i1" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i1} width={150} alt="" />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i2" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i2} width={150} alt="" />
                </button>
              </div>
            </div>
            <div className="flex mt-1">
              <div className="border bg-gray-100">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i3" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i3} width={150} alt="" />
                </button>
              </div>
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "i4" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={i4} width={150} alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="ml-1">
            <div className="text-center bg-gray-200 border-b-2 border-gray-300 mb-1">
              SIDE
            </div>
            <div className="flex">
              <ImageWidget
                setSelected={setSelected}
                setVisible={setVisible}
                editable={editable}
                image={s1}
              />
              <div className="border bg-gray-100 ml-1">
                <button
                  onClick={() => {
                    if (editable) {
                      setSelected({ position: "s2" });
                      setVisible(true);
                    }
                  }}
                  className=""
                >
                  <img src={s2} width={150} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadImage
        isVisible={isVisible}
        setVisible={setVisible}
        selected={selected}
        productId={vehicleId}
      />
    </div>
  );
}
