import { useState } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";
import UpdatePublish from "./UpdatePublish";
import UpdateOnsale from "./UpdateOnsale";
import UpdateForsale from "./UpdateForsale";

export default function PublishWidget({
  is_published,
  is_on_sale,
  is_for_sale,
  productId,
}: any) {
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const [forsaleModal, setForsaleModal] = useState<boolean>(false);
  const [onsaleModal, setOnsaleModal] = useState<boolean>(false);
  return (
    <div className="mb-4">
      <div className="flex justify-between items-center border-b border-t bg-gray-100 px-2 py-3">
        <div className="">
          <span className="font-bold">DOSH</span>
          <span className="font-thin">CARS</span> App
        </div>
      </div>
      <div className="flex justify-between p-2 text-xs">
        <div className="">Published to the website?</div>
        <div className="">
          {is_published ? (
            <button
              onClick={() => setPublishModal(true)}
              className="flex items-center"
            >
              <BsCheck2Square color="#16a34a" />
              <span className="ml-1 text-green-600">YES</span>
            </button>
          ) : (
            <button
              onClick={() => setPublishModal(true)}
              className="flex items-center"
            >
              <AiOutlineCloseSquare color="#dc2626" />
              <span className="ml-1 text-red-600">NO</span>
            </button>
          )}
        </div>
      </div>

      <div className="flex justify-between p-2 text-xs">
        <div className="">Is Product For Sale?</div>
        <div className="">
          {is_for_sale ? (
            <button
              onClick={() => setForsaleModal(true)}
              className="flex items-center"
            >
              <BsCheck2Square color="#16a34a" />
              <span className="ml-1 text-green-600">YES</span>
            </button>
          ) : (
            <button
              onClick={() => setForsaleModal(true)}
              className="flex items-center"
            >
              <AiOutlineCloseSquare color="#dc2626" />
              <span className="ml-1 text-red-600">NO</span>
            </button>
          )}
        </div>
      </div>

      <div className="flex justify-between p-2 text-xs">
        <div className="">Is Product on Sale?</div>
        <div className="">
          {is_on_sale ? (
            <button
              onClick={() => setOnsaleModal(true)}
              className="flex items-center"
            >
              <BsCheck2Square color="#16a34a" />
              <span className="ml-1 text-green-600">YES</span>
            </button>
          ) : (
            <button
              onClick={() => setOnsaleModal(true)}
              className="flex items-center"
            >
              <AiOutlineCloseSquare color="#dc2626" />
              <span className="ml-1 text-red-600">NO</span>
            </button>
          )}
        </div>
      </div>
      <UpdatePublish
        isVisible={publishModal}
        setVisible={setPublishModal}
        is_published={is_published}
        productId={productId}
      />
      <UpdateOnsale
        isVisible={onsaleModal}
        setVisible={setOnsaleModal}
        is_on_sale={is_on_sale}
        productId={productId}
      />

      <UpdateForsale
        isVisible={forsaleModal}
        setVisible={setForsaleModal}
        is_for_sale={is_for_sale}
        productId={productId}
      />
    </div>
  );
}
