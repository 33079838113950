import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const createInquiry = async ({ data }: any) => {
  const response = await postData({ data, uri: "inquiries" });
  return response;
};

const fetchInquiries = async () => {
  const response = await fetchData({ uri: "inquiries" });
  return response;
};

const fetchInquiry = async ({ id }: any) => {
  const response = await fetchData({ uri: `inquiries/${id}` });
  return response;
};

const updateInquiry = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `inquiries/${id}` });
  return response;
};

const deleteInquiry = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `inquiries/${id}` });
  return response;
};

export {
  createInquiry,
  updateInquiry,
  fetchInquiries,
  fetchInquiry,
  deleteInquiry,
};
