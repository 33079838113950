import { Tooltip } from "antd";
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import {
  HiOutlineClipboardDocumentList,
  HiOutlineClipboardDocumentCheck,
  HiOutlineClipboardDocument,
} from "react-icons/hi2";
import UpdateLocation from "./UpdateLocation";
import { FiEdit } from "react-icons/fi";
import UpdateStatus from "./UpdateStatus";

export default function ProductHeader({ product, editable, productId }: any) {
  const [locationModal, setLocationModal] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  return (
    <div className="flex justify-between border-b">
      <div className="p-4">
        <div className="flex text-lg">
          <div className="font-bold uppercase">{product?.name}</div>
          <div className="flex items-center px-2">
            [ {product?.status}
            {editable && (
              <button onClick={() => setStatusModal(true)} className="mx-1">
                <FiEdit size={16} />
              </button>
            )}
            ]
          </div>
        </div>
        <div className="">SKU: {product?.sku}</div>
      </div>

      <div className="p-4 flex flex-col items-end">
        <div className="flex">
          <Tooltip title="Spec Sheet">
            <div className="bg-blue-600 p-2 rounded-md">
              <HiOutlineClipboardDocumentList color="#FFF" size={23} />
            </div>
          </Tooltip>
          <Tooltip title="Inspection Sheet">
            <div className="bg-green-600 p-2 rounded-md ml-2">
              <HiOutlineClipboardDocumentCheck color="#FFF" size={23} />
            </div>
          </Tooltip>
          <Tooltip title="Contract">
            <div className="bg-green-600 p-2 rounded-md ml-2">
              <HiOutlineClipboardDocument color="#FFF" size={23} />
            </div>
          </Tooltip>
        </div>
        <button
          onClick={() => setLocationModal(true)}
          className="flex items-center mt-1"
        >
          <div className="flex mr-1 items-center">
            <span className="mr-1">{product?.category}, {" "}</span>
            <span>{product?.subcat}</span>
          </div>

          <BsChevronDown />
        </button>
      </div>
      <UpdateLocation
        isVisible={locationModal}
        setVisible={setLocationModal}
        vehicle={product || {}}
        vehicleId={productId}
      />
      <UpdateStatus
        isVisible={statusModal}
        setVisible={setStatusModal}
        vehicle={product || {}}
        vehicleId={productId}
      />
    </div>
  );
}
