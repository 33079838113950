import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation,  useQueryClient } from "@tanstack/react-query";
import { createPost } from "../../../../api/posts";

const { TextArea } = Input;

const CreatePost = ({ product, isVisible, setVisible }: any) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createPost,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["products"] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const { title, description } = await form.validateFields();

    const data = {
      title,
      description,
      status: "Available",
      views: Number(0),
      brand: product?.brand,
      product_id: product?.id,
    };
    mutate({ data });
  };

  return (
      <Modal
        title="Create Product"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => form.resetFields()}>
            Clear
          </Button>,
          <Button
            key="submit"
            form="createPost"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createPost"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter title",
                  },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: false,
                    message: "Please select description",
                  },
                ]}
              >
                <TextArea placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
  );
};

export default CreatePost;
