import { useQuery } from "@tanstack/react-query";
import ManageWrapper from "..";
import Something from "../../../components/common/Something";
import CreateCustomer from "./components/CreateCustomer";
import UpdateCustomer from "./components/UpdateCustomer";
import { useState } from "react";
import DeleteCustomer from "./components/DeleteCustomer";
import DetailsPanel from "./components/DetailsPanel";
import { Table } from "antd";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { fetchUsers } from "../../../api/users";

export default function AllCustomers() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const { data, refetch, isFetching, isError } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
  });

  const columns: any = [
    {
      title: "Name",
      width: 220,
      render: (record: any) => <div className="">{record?.name || "-"}</div>,
    },
    {
      title: "Email",
      width: 140,
      render: (record: any) => <div className="">{record?.email || "-"}</div>,
    },
    {
      title: "Mobile",
      width: 140,
      render: (record: any) => <div className="">{record?.mobile || "-"}</div>,
    },
    {
      title: "Category",
      width: 120,
      render: (record: any) => (
        <div className="">{record?.isCompany ? "Company" : "Individual"}</div>
      ),
    },
    {
      title: "Country",
      width: 120,
      render: (record: any) => (
        <div className="">
          {record?.country?.flag || ""} {record?.country?.name || "-"}
        </div>
      ),
    },
    {
      title: "Status",
      width: 120,
      render: (record: any) => (
        <div className="">{record?.is_active ? "Active" : "In Active"}</div>
      ),
    },
    {
      title: "Last Seen",
      width: 120,
      render: (record: any) => (
        <div className="">
          {record?.UpdatedAt ? (
            <Moment fromNow>{record?.UpdatedAt}</Moment>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      align: "right" as "right",
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected(record);
              setUpdateModal(true);
            }}
            className="border bg-yellow-100 rounded-md p-2 mr-1"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => {
              setSelected(record);
              setDeleteModal(true);
            }}
            className="border bg-red-100 rounded-md p-2 mr-1"
          >
            <RiDeleteBin4Line />
          </button>
          <Link
            to={`/manage/customers/${record?.id}`}
            className="border bg-blue-100 rounded-md p-2"
          >
            <BsEye />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <ManageWrapper
      title={"Customers"}
      subtitle={"List of all customers"}
      actions={
        <>
          <CreateCustomer />
        </>
      }
    >
      <div className="mb-16">
        {isError ? (
          <div className="py-2">
            <Something refetch={refetch} isFetching={isFetching} />
          </div>
        ) : (
          <div className="">
            <Table columns={columns} dataSource={data} size="small" />
          </div>
        )}
        <UpdateCustomer
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DeleteCustomer
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          setSelected={setSelected}
        />
        <DetailsPanel
          isVisible={detailModal}
          setVisible={setDetailModal}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
