import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import uploadFile from "../services/uploadFile";

const createSeller = async ({ data }: any) => {
  const response = await postData({ data, uri: "sellers" });
  return response;
};

const fetchSellers = async () => {
  const response = await fetchData({ uri: "sellers" });
  return response;
};

const fetchSeller = async ({ id }: any) => {
  const response = await fetchData({ uri: `sellers/${id}` });
  return response;
};

const updateSeller = async ({ id, data }: any) => {
  const response = await patchData({ data, uri: `sellers/${id}` });
  return response;
};

const uploadImage = async ({ id, file }: any) => {
  const response = await uploadFile({
    file,
    uri: `sellers/${id}/logo`,
  });
  return response;
};

const deleteSeller = async ({ id, data }: any) => {
  const response = await deleteData({ data, uri: `sellers/${id}` });
  return response;
};

export {
  createSeller,
  updateSeller,
  uploadImage,
  fetchSellers,
  fetchSeller,
  deleteSeller,
};
