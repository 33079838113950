import { Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateVehicleStatus } from "../../../../api/vehicles";
import { AiOutlineCheck } from "react-icons/ai";
import { useState } from "react";
import SaleVehicle from "./SaleVehicle";

const UpdateStatus = ({ isVisible, setVisible, vehicle, vehicleId }: any) => {
  const [saleModal, setSaleModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateVehicleStatus,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: (response) => {
      if(response?.success){
        message.success("Updated Successfully");
      }else{
        message.error(`${response?.message}`);
      }
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async ({ status, is_sold }: any) => {
    const data = { status, is_sold };
    mutate({ id: vehicleId, data });
  };

  const hideModal = () => {
    setVisible(false);
  };

  const statuses = [
    { id: 1, is_sold: false, name: "Available" },
    { id: 2, is_sold: false, name: "Reserved" },
    { id: 3, is_sold: false, name: "Negotiation" },
    { id: 4, is_sold: true, name: "Sold" },
  ];

  return (
    <>
      <Modal
        title="Update Status"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <div className="">
          {statuses?.map((status: any, index: number) => (
            <button
              className={`flex items-center bg-gray-100 p-2 mb-2 border w-full ${
                vehicle?.status === status?.name ? "bg-green-100" : ""
              }`}
              onClick={() => {
                if (status?.is_sold) {
                  setSaleModal(true);
                } else {
                  onSubmit({ status: status?.name, is_sold: status?.is_sold });
                }
              }}
              key={index}
            >
              <div className="flex justify-center items-center w-8 h-8 border border-gray-400">
                {vehicle?.status === status?.name ? (
                  <div className="">
                    <AiOutlineCheck size={20} />
                  </div>
                ) : null}
              </div>
              <div className="ml-2">{status?.name}</div>
            </button>
          ))}
        </div>
      </Modal>
      <SaleVehicle
        isVisible={saleModal}
        setVisible={setSaleModal}
        vehicleId={vehicleId}
        hideModal={hideModal}
      />
    </>
  );
};

export default UpdateStatus;
