import { FiEdit } from "react-icons/fi";
import UpdateDetailsOne from "./UpdateDetailsOne";
import { useState } from "react";

export default function VehicleDetailsOne({
  product,
  editable,
  vehicleId,
}: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  return (
    <div className="p-2">
      <div className="flex justify-between items-center border-b bg-gray-100 p-2">
        <div className="">Product Details</div>
        {editable && (
          <button onClick={() => setVisible(true)} className="">
            <FiEdit />
          </button>
        )}
      </div>
      <div className="">
        <DetailItem title={"On Sale"} value={product?.is_on_sale ? "YES": "NO"} />
        <DetailItem title={"Sold"} value={product?.is_sold ? "YES": "NO"} />
        <DetailItem title={"Inventory"} value={product?.ryear || "-"} />
        <DetailItem title={"Discount"} value={product?.trans || "-"} />
        <DetailItem title={"Supplier"} value={product?.body_type || "-"} />
      </div>
      <UpdateDetailsOne
        isVisible={isVisible}
        setVisible={setVisible}
        vehicle={product || {}}
        vehicleId={vehicleId}
      />
    </div>
  );
}

function DetailItem({ title, value }: any) {
  return (
    <div className="flex justify-between items-center border-t p-2">
      <div className="text-xs">{title}</div>
      <div className="text-xs text-gray-500">{value}</div>
    </div>
  );
}
