import { FiEdit } from "react-icons/fi";
import UpdateDetailsTwo from "./UpdateDetailsTwo";
import { useState } from "react";

export default function VehicleDetailsTwo({
  vehicle,
  editable,
  vehicleId,
}: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  return (
    <div className="p-2">
      <div className="flex justify-between items-center border-b bg-gray-100 p-2">
        <div className="">Vehicle Details</div>
        {editable && (
          <button onClick={() => setVisible(true)} className="">
            <FiEdit />
          </button>
        )}
      </div>
      <div className="">
        <DetailItem title={"Mileage"} value={vehicle?.mileage || "-"} />
        <DetailItem title={"Engine Type"} value={vehicle?.engine_type || "-"} />
        <DetailItem title={"Engine Size"} value={vehicle?.engine_size || "-"} />
        <DetailItem title={"Tank Size"} value={vehicle?.tank_size || "-"} />
        <DetailItem title={"No of Seats"} value={vehicle?.seats || "-"} />
        <DetailItem title={"No of Doors"} value={vehicle?.doors || "-"} />
        <DetailItem title={"Max Load"} value={vehicle?.max_load || "-"} />
        <DetailItem title={"Dimension"} value={vehicle?.dimension || "-"} />
        <DetailItem title={"Number Plate"} value={vehicle?.reg_number || "-"} />
      </div>
      <UpdateDetailsTwo
        isVisible={isVisible}
        setVisible={setVisible}
        vehicle={vehicle || {}}
        vehicleId={vehicleId}
      />
    </div>
  );
}

function DetailItem({ title, value }: any) {
  return (
    <div className="flex justify-between items-center border-t p-2">
      <div className="text-xs">{title}</div>
      <div className="text-xs text-gray-500">{value}</div>
    </div>
  );
}
