import { Button, Checkbox, Col, Form, Modal, Row, Select, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { addVehicleFeature } from "../../../../api/vehicles";
import { fetchOptions } from "../../../../api/options";
import extractOptions from "../../../../utils/extractOptions";

const { Option } = Select;

export default function CreateFeature({
  isVisible,
  setVisible,
  vehicle,
  vehicleId,
}: any) {
  const queryClient = useQueryClient();
  const [isActive, setActive] = useState<boolean>(true);
  const [option, setOption] = useState<any>({});

  const { data } = useQuery({
    queryKey: ["options"],
    queryFn: fetchOptions,
  });

  const options = extractOptions(data, vehicle?.features);

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: addVehicleFeature,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Added Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const handleSelected = (id: any) => {
    const filtered = options?.filter((option: any) => option.id === id);
    setOption(filtered[0]);
  };

  const optOptions = options?.map((option: any, index: number) => (
    <Option value={option?.id} key={index}>
      {option?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const data = {
      name: option?.name,
      icon: option?.icon,
      value: isActive,
      option_id: option?.id,
      vehicle_id: vehicle?.id,
    };
    mutate({ id: vehicleId, data });
  };

  return (
    <Modal
      title="Add Feature"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="addVehicleFeature"
          htmlType="submit"
          onSubmit={onSubmit}
          loading={isLoading}
          className="border-0 bg-blue-600 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="addVehicleFeature"
        form={form}
        name="control-ref"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="option_id"
              label="Option"
              rules={[
                {
                  required: true,
                  message: "Please enter region",
                },
              ]}
            >
              <Select placeholder="Option" onSelect={(e) => handleSelected(e)}>
                {optOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Checkbox
              defaultChecked={isActive}
              onChange={() => setActive(!isActive)}
            >
              Active
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
