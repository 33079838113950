import { useQuery } from "@tanstack/react-query";
import ManageWrapper from "..";
import { fetchMakeModels } from "../../../api/makes";
import Something from "../../../components/common/Something";
import { useParams } from "react-router";
import BackToolNav from "../../../components/toolnav/BackToolNav";
import CreateModel from "./components/CreateModel";
import { RiDeleteBin4Line, RiEditBoxLine } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import { Popover } from "antd";
import { useState } from "react";
import UpdateModel from "./components/UpdateModel";
import DeleteModel from "./components/DeleteModel";
import { GoPrimitiveDot } from "react-icons/go";

export default function OneMake() {
  const [selected, setSelected] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { id } = useParams();
  const { data, isError } = useQuery({
    queryKey: ["makes", id],
    queryFn: () => fetchMakeModels({ id }),
  });

  return (
    <ManageWrapper
      title={"Make"}
      subtitle={"List of make models"}
      actions={
        <>
          <CreateModel makeId={id} makeName={data?.name} />
        </>
      }
      toolnav={<BackToolNav />}
    >
      <div className="p-2">
        {isError ? (
          <div className="my-12">
            <Something />
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-2">
            {data?.models?.map((item: any) => (
              <div className="col-span-12 sm:col-span-2 border rounded-md ">
                <div className="flex justify-end p-2">
                  <Popover
                    content={
                      <>
                        <div className="border-t"></div>
                        <button
                          onClick={() => {
                            setUpdateModal(true);
                            setSelected(item);
                          }}
                          className="flex items-center hover:bg-gray-100 p-2 cursor-pointe w-full"
                        >
                          <div className="">
                            <RiEditBoxLine size={15} />
                          </div>
                          <div className="ml-2">Edit</div>
                        </button>
                        <button
                          onClick={() => {
                            setDeleteModal(true);
                            setSelected(item);
                          }}
                          className="flex items-center hover:bg-gray-100 p-2 cursor-pointer w-full"
                        >
                          <div className="">
                            <RiDeleteBin4Line />
                          </div>
                          <div className="ml-2">Delete</div>
                        </button>
                      </>
                    }
                    title={`${item?.name}`}
                    placement="bottom"
                  >
                    <FiMoreVertical />
                  </Popover>
                </div>
                <div className="flex flex-col items-center justify-center p-4 -mt-10">
                  <div className="flex items-center text-blue-800 font-bold mt-4">
                    {item?.name}{" "}
                    <GoPrimitiveDot
                      color={item?.is_active ? "#16a34a" : "#dc2626"}
                    />
                  </div>
                  <div className="text-lg font-bold py-1"> {item?.count}</div>
                  <div className="border py-1 px-4 bg-gray-100 text-blue-800 rounded-md text-xs">
                    View Vehicles
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <UpdateModel
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          make_id={id}
          setSelected={setSelected}
        />

        <DeleteModel
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
          make_id={id}
          setSelected={setSelected}
        />
      </div>
    </ManageWrapper>
  );
}
