import { Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateVehicleStatus } from "../../../../api/vehicles";
import { AiOutlineCheck } from "react-icons/ai";

const UpdateStatus = ({ isVisible, setVisible, vehicle, vehicleId }: any) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateVehicleStatus,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] });
      setVisible(false);
    },
  });

  const onSubmit = async ({ status, is_sold }: any) => {
    const data = { status, is_sold };
    mutate({ id: vehicleId, data });
  };

  const statuses = [
    { id: 1, is_sold: false, name: "Available" },
    { id: 2, is_sold: false, name: "Reserved" },
    { id: 3, is_sold: false, name: "Negotiation" },
    { id: 4, is_sold: true, name: "Sold" },
  ];

  return (
    <Modal
      title="Update Location"
      open={isVisible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="">
        {statuses?.map((status: any, index: number) => (
          <button
            className={`flex items-center bg-gray-100 p-2 mb-2 border w-full ${
              vehicle?.status === status?.name ? "bg-green-100" : ""
            }`}
            onClick={() =>
              onSubmit({ status: status?.name, is_sold: status?.is_sold })
            }
            key={index}
          >
            <div className="flex justify-center items-center w-8 h-8 border border-gray-400">
              {vehicle?.status === status?.name ? (
                <div className="">
                  <AiOutlineCheck size={20} />
                </div>
              ) : null}
            </div>
            <div className="ml-2">{status?.name}</div>
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default UpdateStatus;
