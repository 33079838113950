import { Popover } from "antd";
import { FiMoreVertical } from "react-icons/fi";
import { GoPrimitiveDot } from "react-icons/go";
import { RiDeleteBin4Line, RiEditBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";

export default function MakeWidget({
  item,
  setSelected,
  setUploadModal,
  setUpdateModal,
  setDeleteModal,
}: any) {
  return (
    <div className="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3">
      <div className="flex flex-col border rounded-md items-start justify-center p-4">
        <div className="w-full flex justify-end items-center">
          <div className="">
            <Popover
              content={
                <>
                  <div className="border-t"></div>
                  <button
                    onClick={() => {
                      setSelected(item);
                      setUpdateModal(true);
                    }}
                    className="flex items-center hover:bg-gray-100 p-2 cursor-pointe w-full"
                  >
                    <div className="">
                      <RiEditBoxLine size={15} />
                    </div>
                    <div className="ml-2">Edit</div>
                  </button>
                  <button
                    onClick={() => {
                      setSelected(item);
                      setDeleteModal(true);
                    }}
                    className="flex items-center hover:bg-gray-100 p-2 cursor-pointer w-full"
                  >
                    <div className="">
                      <RiDeleteBin4Line />
                    </div>
                    <div className="ml-2">Delete</div>
                  </button>
                </>
              }
              title={`${item?.name}`}
              placement="bottom"
            >
              <FiMoreVertical />
            </Popover>
          </div>
        </div>
        <div className="flex  justify-center items-center -mt-4">
          <button
            onClick={() => {
              setSelected(item);
              setUploadModal(true);
            }}
            className="flex justify-center items-center border rounded-full h-24 w-24 bg-gray-100"
          >
            <img src={item?.logo} />
          </button>
          <Link
            to={`/manage/makes/${item?.id}`}
            className="flex flex-col pl-4 border-l ml-4"
          >
            <div className="flex items-center text-blue-800 font-bold text-lg uppercase">
              {item?.name}{" "}
              <GoPrimitiveDot color={item?.is_active ? "#16a34a" : "#dc2626"} />
            </div>
            <div className="text-xs">Models: {item?.models?.length}</div>
            <div className="font-bold py-1">{item?.count} Vehicles</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
