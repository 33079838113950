export default function Car({ width, height, color }: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9994 11.7501H3.99937C3.76937 11.7501 3.55937 11.6501 3.41937 11.4701C3.27937 11.3001 3.21937 11.0601 3.26937 10.8401L4.39937 5.44008C4.76937 3.69008 5.51937 2.08008 8.48937 2.08008H15.5194C18.4894 2.08008 19.2394 3.70008 19.6094 5.44008L20.7394 10.8501C20.7894 11.0701 20.7294 11.3001 20.5894 11.4801C20.4394 11.6501 20.2294 11.7501 19.9994 11.7501ZM4.91937 10.2501H19.0694L18.1294 5.75008C17.8494 4.44008 17.5194 3.58008 15.5094 3.58008H8.48937C6.47937 3.58008 6.14937 4.44008 5.86937 5.75008L4.91937 10.2501Z"
        fill={color}
      />
      <path
        d="M19.9596 22.75H18.0796C16.4596 22.75 16.1496 21.82 15.9496 21.21L15.7496 20.61C15.4896 19.85 15.4596 19.75 14.5596 19.75H9.4396C8.5396 19.75 8.4796 19.92 8.2496 20.61L8.0496 21.21C7.8396 21.83 7.5396 22.75 5.9196 22.75H4.0396C3.2496 22.75 2.4896 22.42 1.9596 21.84C1.4396 21.27 1.1896 20.51 1.2596 19.75L1.8196 13.66C1.9696 12.01 2.4096 10.25 5.6196 10.25H18.3796C21.5896 10.25 22.0196 12.01 22.1796 13.66L22.7396 19.75C22.8096 20.51 22.5596 21.27 22.0396 21.84C21.5096 22.42 20.7496 22.75 19.9596 22.75ZM9.4396 18.25H14.5596C16.3796 18.25 16.8096 19.06 17.1696 20.12L17.3796 20.74C17.5496 21.25 17.5496 21.26 18.0896 21.26H19.9696C20.3396 21.26 20.6896 21.11 20.9396 20.84C21.1796 20.58 21.2896 20.25 21.2596 19.9L20.6996 13.81C20.5696 12.46 20.4096 11.76 18.3996 11.76H5.6196C3.5996 11.76 3.4396 12.46 3.3196 13.81L2.7596 19.9C2.7296 20.25 2.8396 20.58 3.0796 20.84C3.3196 21.11 3.6796 21.26 4.0496 21.26H5.9296C6.4696 21.26 6.4696 21.25 6.6396 20.75L6.8396 20.14C7.0896 19.34 7.4596 18.25 9.4396 18.25Z"
        fill={color}
      />
      <path
        d="M4 8.75H3C2.59 8.75 2.25 8.41 2.25 8C2.25 7.59 2.59 7.25 3 7.25H4C4.41 7.25 4.75 7.59 4.75 8C4.75 8.41 4.41 8.75 4 8.75Z"
        fill={color}
      />
      <path
        d="M21 8.75H20C19.59 8.75 19.25 8.41 19.25 8C19.25 7.59 19.59 7.25 20 7.25H21C21.41 7.25 21.75 7.59 21.75 8C21.75 8.41 21.41 8.75 21 8.75Z"
        fill={color}
      />
      <path
        d="M12 5.75C11.59 5.75 11.25 5.41 11.25 5V3C11.25 2.59 11.59 2.25 12 2.25C12.41 2.25 12.75 2.59 12.75 3V5C12.75 5.41 12.41 5.75 12 5.75Z"
        fill={color}
      />
      <path
        d="M13.5 5.75H10.5C10.09 5.75 9.75 5.41 9.75 5C9.75 4.59 10.09 4.25 10.5 4.25H13.5C13.91 4.25 14.25 4.59 14.25 5C14.25 5.41 13.91 5.75 13.5 5.75Z"
        fill={color}
      />
      <path
        d="M9 15.75H6C5.59 15.75 5.25 15.41 5.25 15C5.25 14.59 5.59 14.25 6 14.25H9C9.41 14.25 9.75 14.59 9.75 15C9.75 15.41 9.41 15.75 9 15.75Z"
        fill={color}
      />
      <path
        d="M18 15.75H15C14.59 15.75 14.25 15.41 14.25 15C14.25 14.59 14.59 14.25 15 14.25H18C18.41 14.25 18.75 14.59 18.75 15C18.75 15.41 18.41 15.75 18 15.75Z"
        fill={color}
      />
    </svg>
  );
}
