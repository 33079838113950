import { Button, Form, Modal, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCustomer } from "../../../../api/customers";

const DeleteCustomer = ({ isVisible, setVisible, selected }: any) => {
  const { id } = selected;
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: deleteCustomer,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["customers"] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    mutate({ id });
  };

  return (
    <>
      <Modal
        title="Delete Customer"
        open={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="deleteCustomer"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Delete
          </Button>,
        ]}
      >
        <Form
          id="deleteCustomer"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <div className="flex flex-col justify-center items-center">
            <div className="">
              Are you sure you want to
              <span className="text-red-600 px-1 font-bold">Delete</span>
              Customer
            </div>
            <div className="font-bold">{selected?.name} ?</div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteCustomer;
