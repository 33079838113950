import { useState } from "react";
import placeholder from "../../../../assets/images/placeholder.png";
import UploadImage from "./UploadImage";
export default function MechanicalVimages({ images, vehicleId }: any) {
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  return (
    <div>
      <div className="grid grid-cols-12 gap-2 relative">
        {images?.map((image: any, index: number) => (
          <div className="col-span-3 border border-black" key={index}>
            <img src={image?.url} width={200} />
            <div className="z-50 bg-white px-6 py-1">{image?.position}</div>
          </div>
        ))}
        <button
          onClick={() => setUploadModal(true)}
          className="col-span-3 border border-black w-44 mt-2"
        >
          <img src={placeholder} width={200} />
          <div className="z-50 bg-white px-6 py-1">m{images?.length + 1}</div>
        </button>
      </div>

      <UploadImage
        isVisible={uploadModal}
        setVisible={setUploadModal}
        vehicleId={vehicleId}
        selected={{ position: `m${images?.length + 1}`, category: "mechanical" }}
      />
    </div>
  );
}
