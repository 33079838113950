import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { fetchAllOrders } from "../../api/orders";
import moment from "moment";
import { Link } from "react-router-dom";

export default function AllOrders() {
  const [cursor, setCursor] = useState<any>(0);
  const { data } = useQuery({
    queryKey: ["orders", cursor],
    queryFn: () => fetchAllOrders({ cursor }),
  });
  return (
    <div className="m-4">
      {data?.data?.map((order: any, index: number) => (
        <Link to={`/orders/${order?.id}`} className="flex justify-between pb-2 mb-2 border-b" key={index}>
          <div className="flex">

            <div className="">{index + 1}.</div>
            <div className="">           
          <div className="flex">
            <div className="">ORDER </div>
            <div className="text-blue-600">
              #{1000 + order?.id}
            </div>
          </div>
          <div className="">
            <div className="font-bold">{order?.address?.name}</div>
            <div className="">{order?.address?.street},{order?.address?.street} </div>
            <div className="">{order?.address?.municipal},{order?.address?.city}, {order?.address?.country} </div>
          </div>
            </div>
            </div>
            <div className="">
              <div className="uppercase">{moment(order?.created_at).format("Do MMM, YYYY")}</div>
              <div className="">{order?.status}</div>
            </div>
        </Link>
      ))}
    </div>
  );
}
