import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Drawer, Form, Input, Row, Space, message } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { updateRegion } from "../../../../api/regions";
import { useEffect } from "react";

export default function UpdateRegion({ isVisible, setVisible, selected }: any) {
  const { id, name, country_id } = selected;

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateRegion,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["countries", country_id] });
      setVisible(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = {
      is_active: true,
      country_id: Number(country_id),
      ...values,
    };
    mutate({ id, data });
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <Drawer
      title={`Update City / Region`}
      placement={"right"}
      width={500}
      closable={false}
      onClose={() => setVisible(false)}
      open={isVisible}
      extra={
        <Space>
          <Button danger onClick={() => setVisible(false)}>
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <Form
          id="UpdateRegion"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="name"
                label="Region Name"
                initialValue={name}
                rules={[
                  {
                    required: true,
                    message: "Please enter region name",
                  },
                ]}
              >
                <Input placeholder="Region Name" />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="border-0 bg-blue-600 text-white mr-2"
                loading={isLoading}
              >
                Update
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Drawer>
  );
}
