import React, { useState } from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBranch } from "../../../../api/branches";
import { AiOutlinePlus } from "react-icons/ai";

const CreateBranch: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isActive, setActive] = useState<boolean>(true);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: createBranch,
    onError: (error) => {
      message.error(`${error}`);
    },
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["branches"] });
      setOpen(false);
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { is_active: isActive, count: 0, ...values };
    mutate({ data });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <AiOutlinePlus />
      </Button>
      <Modal
        title="Create Branch"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            form="createBranch"
            htmlType="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            className="border-0 bg-blue-600 text-white"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="createBranch"
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Branch Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter branch name",
                  },
                ]}
              >
                <Input placeholder="Branch Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="email"
                label="Branch Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter branch email",
                  },
                ]}
              >
                <Input placeholder="Branch Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="mobile"
                label="Branch Mobile"
                rules={[
                  {
                    required: true,
                    message: "Please enter branch mobile",
                  },
                ]}
              >
                <Input placeholder="Branch mobile" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="street"
                label="Branch Street"
                rules={[
                  {
                    required: true,
                    message: "Please enter branch street",
                  },
                ]}
              >
                <Input placeholder="Branch street" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="postal_code"
                label="Postal Code"
                rules={[
                  {
                    required: true,
                    message: "Please enter postal code",
                  },
                ]}
              >
                <Input placeholder="Postal Code" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="plot_no"
                label="Plot No."
                rules={[
                  {
                    required: true,
                    message: "Please enter branch plot",
                  },
                ]}
              >
                <Input placeholder="Branch plot" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="house_no"
                label="House No."
                rules={[
                  {
                    required: true,
                    message: "Please enter branch house no",
                  },
                ]}
              >
                <Input placeholder="Branch house no" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Checkbox
                defaultChecked={isActive}
                onChange={() => setActive(!isActive)}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateBranch;
